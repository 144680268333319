import { BaseModel } from './base.model';

export class Period extends BaseModel {
  constructor(
    id?: string,
    displayName?: string,
    startDate?: string,
    endDate?: string,
    periodType?: string
  ) {
    super(id);
    this.displayName = displayName;
    this.startDate = startDate;
    this.endDate = endDate;
    this.periodType = periodType;
  }

  displayName: string;
  startDate: string;
  endDate: string;
  periodType:string;
}
