import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddOnSale } from 'src/app/models/addon-sale.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-addons-verify-modal',
  templateUrl: './addons-verify-modal.component.html',
  styleUrls: ['./addons-verify-modal.component.css']
})
export class AddonsVerifyModalComponent implements OnInit {

  @Input() verifyForm: FormGroup;
  @Input() item: AddOnSale;
  @Output() updateItem = new EventEmitter<AddOnSale>();

  constructor() { }

  ngOnInit() {

  }

  verifyItem() {
    this.item.checkId = this.verifyForm.value.checkId;
    this.updateItem.emit(this.item);
  }

}
