import { Component, OnInit } from '@angular/core';
import { CallLogService } from 'src/app/services/call-log.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { EmployeeCallCountModel } from 'src/app/models/employee-call-count.model';
import { Period } from 'src/app/models/period.model';
import { PeriodService } from 'src/app/services/period.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeService } from 'src/app/services/employee.service';
import { MessageModel } from 'src/app/models/message.model';

@Component({
  selector: 'app-mgmt-call-log',
  templateUrl: './mgmt-call-log.component.html',
  styleUrls: ['./mgmt-call-log.component.css']
})
export class MgmtCallLogComponent implements OnInit {

  employees: EmployeeCallCountModel[];
  period: Period;
  periods: Period[];
  loading: boolean;
  loadingMessageTmp = 'Loading Summaries...';
  loadingMessage;
  periodId: string;
  emptyList: boolean;

  constructor(private logs: CallLogService, private periodService: PeriodService,
    private constants: ConstantsService, private es: EmployeeService,
    private actRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.actRoute.data.subscribe((d: { periods: Period[], period: Period, employees: EmployeeCallCountModel[] }) => {
      this.period = d.period;
      this.periods = d.periods;
      this.periodId = this.period.id;
      this.getSummary();
    },
      err => this.constants.sendMessage.next(new MessageModel('Failed to load data!', 'alert-danger')));
  }

  getSummary() {
    this.loadingMessage = this.loadingMessageTmp;
    this.loading = true;
    this.logs.getEmployeeSummary(this.period.id).subscribe(e => {
      this.employees = e;
      this.loading = false;

      if (this.employees && this.employees.length > 0) {
        this.emptyList = false;
      } else {
        this.emptyList = true;
      }
    },
      err => {
        this.loading = false;
        this.constants.sendMessage.next(new MessageModel('Failed to load Summaries!', 'alert-danger'));
      });
  }

  getDetails(empId: string) {
    this.loading = true;
    this.loadingMessage = 'Loading Details...';
    this.router.navigate(['/mgmt/calllog/' + this.period.id + '/' + empId]);
  }

  assignCalls(empId: string) {
    this.es.createCallList(empId).subscribe(() => {
      this.getSummary();
      this.constants.sendMessage.next(new MessageModel('Calls have been assigned!', 'alert-success'));
    });
  }

  clearCalls(empId: string) {
    this.es.clearAssignedCalls(empId).subscribe(() => {
      this.getSummary();
      this.constants.sendMessage.next(new MessageModel('Calls have been cleared!', 'alert-success'));
    });
  }

  changePeriod(p: string) {
    this.loading = true;
    this.periodService.getItem(p).subscribe(d => {
      this.period = d;
      this.getSummary();
    });
  }
}
