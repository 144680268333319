import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { StoreStat } from 'src/app/models/store-stat.model';
import { Period } from 'src/app/models/period.model';

@Component({
  selector: 'app-goals-stats-form',
  templateUrl: './goals-stats-form.component.html',
  styleUrls: ['./goals-stats-form.component.css']
})
export class GoalsStatsFormComponent implements OnInit {

  @Input() period: Period;
  @Input() storeStat: StoreStat;
  @Input() statsForm: FormGroup;
  @Input() submitting: boolean;
  @Output() updateStats = new EventEmitter<StoreStat>();

  constructor(private router: Router) { }

  ngOnInit() {
    this.submitting = false;
  }

  submitNumbers() {
    this.submitting = true;
    this.storeStat.closeRate = this.statsForm.value.closeRate;
    this.storeStat.cancels = this.statsForm.value.cancels;
    this.storeStat.freezes = this.statsForm.value.freezes;
    this.storeStat.totalMembers = this.statsForm.value.totalMembers;
    this.storeStat.prospects = this.statsForm.value.prospects;
    this.storeStat.newMembers = this.statsForm.value.newMembers;

    this.updateStats.emit(this.storeStat);
  }

  goBack() {
    this.router.navigate(['mgmt/pitches' + '/' + this.period.id]);
  }
}
