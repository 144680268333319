import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Guest } from 'src/app/models/guest.model';
import { GuestService } from '../guest.service';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GuestListResolverService implements Resolve<Guest[]> {

  constructor(private svc: GuestService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Guest[]> | Observable<never> {
    const pId = route.paramMap.get('partitionId');

    return this.svc.getByPartition(pId).pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
