import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WaitList } from 'src/app/models/waitlist-model';

@Component({
  selector: 'app-waitlist-form',
  templateUrl: './waitlist-form.component.html',
  styleUrls: ['./waitlist-form.component.css']
})
export class WaitlistFormComponent implements OnInit {

  @Input() waitForm: FormGroup;
  @Output() submitItem = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  submitForm() {
    const item = new WaitList('',
      this.waitForm.value.clientName,
      this.waitForm.value.phone,
      this.waitForm.value.appointmentDate,
      this.waitForm.value.appointmentTime,
      this.waitForm.value.requestDetails);
  
    this.submitItem.emit(item);
  }

}
