import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageModel } from 'src/app/models/message.model';
import { NewMember } from 'src/app/models/new-member.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { NewMembersService } from 'src/app/services/new-members.service';

@Component({
  selector: 'app-new-members-view',
  templateUrl: './new-members-view.component.html',
  styleUrls: ['./new-members-view.component.css']
})
export class NewMembersViewComponent implements OnInit {

  constructor(private auth: AuthService, private constants: ConstantsService,
     private actRouter: ActivatedRoute, private router: Router, private nmService: NewMembersService) { }

  emptyList: boolean;
  loading: boolean;
  loadingMessage: string;
  filter: string;
  listTitle: string;
  members: NewMember[];

  ngOnInit() {
    this.emptyList = true;
    this.loading = true;
    this.loadingMessage = 'Loading New Members...';
    this.listTitle =  'New Members';
    this.loadMembers();
  }

  loadMembers() {
    this.nmService.getAll().subscribe(d => {
      this.members = d;
      if (d && d.length > 0) {
        this.emptyList = false;
      } else {
        this.emptyList = true;
      }
      this.loading = false;
    },
      err => {
        this.constants.sendMessage.next(new MessageModel('Error Loading Members', 'alert-danger'));
        this.loading = false;
    });
  }

  addMember() {
    this.router.navigate(['nm/new']);
  }

  removeMember(m: NewMember) {
    this.loading = true;
    this.loadingMessage = 'Deleting ' + m.displayName;
    this.nmService.remove(m).subscribe((a: any) => {
      this.constants.sendMessage.next(new MessageModel('Deleted Member ' + m.displayName, 'alert-success'));
      this.loadingMessage = 'Updating List...';
      this.loadMembers();
    }, (err: any) => {
      this.loading = false;
      this.constants.sendMessage.next(new MessageModel('Error Deleting ' + m.displayName, 'alert-danger'));
      this.loadMembers();
    });
  }
}
