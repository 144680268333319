// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_code: '',
  // api_host: 'http://localhost:7071/',
  // redirect_uri: 'http://localhost:4200',
  api_code: '?code=/f8zw1/EBcWk1TqL4aL9TtLSkHdvppIek7K3QeFvirNd3mi9yGlAaA==',
  api_host: 'https://mlpp-fd-api.azurewebsites.net/',
  redirect_uri: 'https://fd.mlpines.com',
  clientID: 'e7382873-2cfc-43c3-a14d-f511b8bda19c',
  tenantId: '516bc435-bfe8-4e78-a6d9-4c145cce5ac5',
  authority: 'https://mlpines.b2clogin.com/tfp/mlpines.onmicrosoft.com/B2C_1_fdSi/v2.0'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
