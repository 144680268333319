import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Guest } from 'src/app/models/guest.model';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-modal-call-script',
  templateUrl: './modal-call-script.component.html',
  styleUrls: ['./modal-call-script.component.css']
})
export class ModalCallScriptComponent implements OnInit {

  @Input() guest: Guest;
  @Input() script: string;
  @Input() filter: string;
  @Output() updateItem = new EventEmitter();

  closeResult: string;
  isEftDecline: boolean;
  is1YrGuest: boolean;

  constructor(private constants: ConstantsService) { }

  ngOnInit() {
    switch (this.filter) {
      case (this.constants.guest_partitions_1yr):
      case (this.constants.guest_partitions_36mo):
        this.isEftDecline = false;
        this.is1YrGuest = true;
        break;
      case (this.constants.guest_partitions_declinedEft):
      case (this.constants.guest_partitions_delinquent):
        this.is1YrGuest = false;
        this.isEftDecline = true;
        break;
      default:
        this.is1YrGuest = false;
        this.isEftDecline = false;
        break;
    }
  }

  closeCall() {
    this.guest = null;
    this.script = null;
  }

  setStatus(callResult: string): void {
    // Call Api and Close modal
    // const emp = this.authService.getPinnedUser();
    // this.logService.logCall(status, this.guest, emp).subscribe(() => this.closeCall());
    // console.log('captured click');
    // console.log(JSON.stringify(this.guest));
    // console.log('CallResultFromModal: ' + callResult);
    this.guest.lastCallResult = callResult;
    this.updateItem.emit(this.guest);
  }

}
