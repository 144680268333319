import { FormControl } from '@angular/forms';

// https://stackoverflow.com/questions/51905033/pre-populating-and-validating-date-in-angular-6-reactive-form
export function CheckUrlValidator(): any {
  return (control: FormControl): { [key: string]: any } => {
    const checkUrlBase = 'https://luxe.bookbyaliens.com/check/';
    const checkPrintUrlBase = 'https://luxe.bookbyaliens.com/print/check/';

    // if ((!control.value.startsWith(checkUrlBase)) && (!control.value.startsWith(checkPrintUrlBase))) {
    if (!control.value.startsWith(checkPrintUrlBase)) {
      return { invalidUrl: true };
    }

    return null;
  };
}
