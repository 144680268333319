import { Injectable } from '@angular/core';
import * as Msal from 'msal';
import { ConstantsService } from './constants.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JwtHelper } from './jwt-helper.service';
import { Observable } from 'rxjs';
import { Employee } from '../models/employee.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router, private constants: ConstantsService) { }

  loginRedirectUrl = '';

  graphScopes = ['user.read', 'mail.send'];

  // Msal = new Msal.UserAgentApplication(
  //   environment.clientID,
  //   environment.authority,
  //   this.acquireTokenRedirectCallBack,
  //   { storeAuthStateInCookie: true, cacheLocation: 'localStorage' });

  Logger = new Msal.Logger(this.loggerCallback, { level: Msal.LogLevel.Verbose, correlationId: '1234' });

  Msal = new Msal.UserAgentApplication(
    environment.clientID, environment.authority, this.acquireTokenRedirectCallBack.bind(this), {
      logger: this.Logger,
      storeAuthStateInCookie: true, cacheLocation: 'localStorage',
      validateAuthority: false
    });

  loggerCallback(logLevel, message, piiLoggingEnabled) {
    console.log(message);
  }

  handleError(error: Response) {
    console.error(error);
    return Observable.throw(error);
  }

  login() {
    console.log('calling Login');
    // this.Msal.loginRedirect(null, 'login_hint=fd%40mlpines.com');
    this.Msal.loginPopup(null, 'login_hint=fd%40mlpines.com')
      .then(function (authResult) {
        // login success
        // this.acquireTokenSilent(authResult);

        console.log('Login Success!!!!');
        const jwt = new JwtHelper();
        const token = jwt.decodeToken(authResult);

        console.log('Token: ' + JSON.stringify(token));

        this.setSession(token);
        this.router.navigate([this.constants.redirectHome]);

      }.bind(this), function (error) {
        // login failure
        console.error('Login Fail!!!!');
        console.log(error);
      }.bind(this));
  }

  acquireTokenRedirectCallBack(errorDesc, token, error, tokenType) {
    console.log('signed in call back!!...checking token');
    if (token) {
      console.log('signed in callback');
      // TODO:  Do somes Stuff with the token like putting it into the local store?
      this.setSession(token, token);
      this.router.navigate([this.constants.redirectHome]);
    } else {
      console.log(error + ': ' + errorDesc);
    }
  }

  getTokenSilently() {
    console.log('Silet token');
    // this.Msal.loginPopup(this.graphScopes).then(function (idToken) {
    //   // Login Success
    //   // tslint:disable-next-line:only-arrow-functions
    //   this.Msal.acquireTokenSilent(this.graphScopes).then(function (accessToken) {
    //     // AcquireTokenSilent Success
    //   }, function (error) {
    //     // AcquireTokenSilent Failure, send an interactive request.
    //     this.Msal.acquireTokenPopup(this.graphScopes).then(function (accessToken) {
    //       this.updateUI();
    //       // tslint:disable-next-line:only-arrow-functions
    //     }, function (error2) {
    //       console.log(error2);
    //     });
    //   });
    //   // tslint:disable-next-line:only-arrow-functions
    // }, function (error) {
    //   // login failure
    //   console.log(error);
    // });
  }

  setSession(token, authResult): void {
    console.log('setting Session');
    // Set the time that the access token will expire
    const expiresAt = JSON.stringify((token.exp * 1000) + new Date().getTime());

    // if there is something in authResult.scope then the claims differ than those requested and this should be used.
    // Otherwise use the requested scopes
    // Else no scopes!
    // const scopes = token.scope || this.graphScopes || '';

    localStorage.setItem(this.constants.key_access_token, token);
    localStorage.setItem(this.constants.key_expires_at, expiresAt);
    // localStorage.setItem(this.constants.key_access_token, authResult.accessToken);
    // localStorage.setItem(this.constants.key_token_id, authResult.idToken);
    // localStorage.setItem(this.constants.key_scopes, JSON.stringify(scopes));
  }

  public logout(): void {
    this.Msal.logout();
    // remove tokens and expirey time from localStoreage
    localStorage.removeItem(this.constants.key_access_token);
    localStorage.removeItem(this.constants.key_token_id);
    localStorage.removeItem(this.constants.key_expires_at);
    localStorage.removeItem(this.constants.key_scopes);
    sessionStorage.removeItem(this.constants.key_pin_user);

    // Go back to the home Route
    this.router.navigate([this.constants.redirectLogin]);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the access token's expiry date
    const expiresAt = JSON.parse(localStorage.getItem(this.constants.key_expires_at));
    const isExpired = new Date().getTime() < expiresAt;
    return new Date().getTime() < expiresAt;
  }

  public pinIn(pin: string): Observable<Employee> {
    return this.http.post<Employee>(this.constants.api_employee_byPin + environment.api_code, { Pin: pin })
      .pipe(catchError(this.handleError));
  }

  public pinOut() {
    sessionStorage.removeItem(this.constants.key_pin_user);
    this.router.navigate([this.constants.redirectHome]);
  }

  public isPinned(): boolean {
    if (sessionStorage.getItem(this.constants.key_pin_user)) {
      return true;
    }

    return false;
  }

  public getPinnedUser() {
    return JSON.parse(sessionStorage.getItem(this.constants.key_pin_user));
  }

  public isManager() {
    const e = JSON.parse(sessionStorage.getItem(this.constants.key_pin_user));
    if (e) {
      return e.isManager;
    }

    return false;
  }

  public isAdmin() {
    const e = JSON.parse(sessionStorage.getItem(this.constants.key_pin_user));
    if (e) {
      return e.isAdmin;
    }

    return false;
  }

}
