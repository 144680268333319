import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataSegment } from 'src/app/models/data-segment.model';
import { AddOnSale } from 'src/app/models/addon-sale.model';

@Component({
  selector: 'app-addons-form',
  templateUrl: './addons-form.component.html',
  styleUrls: ['./addons-form.component.css']
})
export class AddonsFormComponent implements OnInit {

  @Input() itemForm: FormGroup;
  @Input() addOns: DataSegment[];
  @Output() saveItem = new EventEmitter<AddOnSale>();

  constructor() { }

  ngOnInit() {

  }

  submitForm() {
    // console.log('entered Date: ' + this.itemForm.value.appointmentDate);
    const today = new Date();

    const item = new AddOnSale('', '', today.toDateString(),
      this.itemForm.value.appointmentDate,
      this.itemForm.value.addOnId,
      this.itemForm.value.clientName);

    this.saveItem.emit(item);
  }

}
