import { Component, OnInit, Input } from '@angular/core';
import { StoreStat } from 'src/app/models/store-stat.model';

@Component({
  selector: 'app-goals-stats-view',
  templateUrl: './goals-stats-view.component.html',
  styleUrls: ['./goals-stats-view.component.css']
})
export class GoalsStatsViewComponent implements OnInit {

  @Input() stats: StoreStat;
  @Input() statsAvailable: boolean;

  constructor() { }

  ngOnInit() {
      
  }

}
