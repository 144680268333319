import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-mgmt-nav',
  templateUrl: './mgmt-nav.component.html',
  styleUrls: ['./mgmt-nav.component.css']
})
export class MgmtNavComponent implements OnInit {

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }

}
