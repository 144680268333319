import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Period } from 'src/app/models/period.model';

@Component({
  selector: 'app-periods-selector',
  templateUrl: './periods-selector.component.html',
  styleUrls: ['./periods-selector.component.css']
})
export class PeriodsSelectorComponent implements OnInit {

  @Input() periods: Period[];
  @Input() currentPeriod: Period;
  @Output() selected = new EventEmitter<Period>();

  constructor() { }

  ngOnInit() {
  }

  updateSelection(p: Period) {
    this.selected.emit(p);
  }

}
