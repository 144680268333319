import { BaseModel } from './base.model';
import { Period } from './period.model';
import { Employee } from './employee.model';

export class ClosingNumber extends BaseModel {
  constructor(
    id?: string,
    pitchCount?: number,
    pitchSuccesses?: number,
    closeRate?: number,
    goal?: number,
    periodId?: string,
    period?: Period,
    employeeId?: string,
    employee?: Employee,
    created?: string
  ) {
    super(id);

    this.pitchCount = pitchCount;
    this.closeRate = closeRate;
    this.goal = goal;
    this.period = period;
    this.periodId = periodId;
    this.pitchSuccesses = pitchSuccesses;
    this.employee = employee;
    this.employeeId = employeeId;
    this.created = created;
  }
  employeeId: string;
  employee: Employee;
  pitchSuccesses: number;
  displayName: string;
  pitchCount: number;
  closeRate: number;
  goal: number;
  periodId: string;
  period: Period;
  created: string;
  goalMet: boolean;
}
