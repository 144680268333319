import { Injectable } from '@angular/core';
import { PeriodService } from '../period.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { Period } from 'src/app/models/period.model';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PeriodsResolverService implements Resolve<Period[]> {

  constructor(private svc: PeriodService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Period[]> | Observable<never> {

    return this.svc.getList('Month').pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
