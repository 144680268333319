import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeAddOnCountModel } from 'src/app/models/employee-addon-count.model';
import { Period } from 'src/app/models/period.model';
import { AddonSalesService } from 'src/app/services/addon-sales.service';
import { PeriodService } from 'src/app/services/period.service';

@Component({
  selector: 'app-addons-scoreboard',
  templateUrl: './addons-scoreboard.component.html',
  styleUrls: ['./addons-scoreboard.component.css']
})
export class AddonsScoreboardComponent implements OnInit {

  periods: Period[];
  currentPeriod: Period;
  period: Period;
  loading: boolean;
  emptyList: boolean;
  employees: EmployeeAddOnCountModel[];
  loadingMessage = 'Loading Addon Leaders...';

  constructor(private addOns: AddonSalesService,
    private ps: PeriodService, private actRoute: ActivatedRoute) { }

  ngOnInit() {
    this.actRoute.data.subscribe((d: { period: Period }) => {
      this.period = d.period;
      this.getSummary();
    });
  }

  getSummary() {
    this.loading = true;
    this.addOns.getSalesSummary(this.period.id).subscribe(d => {
      this.employees = d;
      this.loading = false;
    }, err => {
      console.log('ERROR: ' + JSON.stringify(err));
      this.loading = false;
    });
  }
}
