import { Component, OnInit } from '@angular/core';
import { MessageModel } from 'src/app/models/message.model';
import { ConstantsService } from 'src/app/services/constants.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { Guest } from 'src/app/models/guest.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CallLogService } from 'src/app/services/call-log.service';
import { Employee } from 'src/app/models/employee.model';
import { Period } from 'src/app/models/period.model';
import { PeriodService } from 'src/app/services/period.service';

@Component({
  selector: 'app-mgmt-call-log-details',
  templateUrl: './mgmt-call-log-details.component.html',
  styleUrls: ['./mgmt-call-log-details.component.css']
})
export class MgmtCallLogDetailsComponent implements OnInit {

  listTitle: string;
  guests: Guest[];
  emptyList: boolean;
  loading: boolean;
  calledCount: number;
  bookedCount: number;
  callsTitle: string;
  employee: Employee;
  period: Period;
  periods: Period[];

  constructor(private callLog: CallLogService, private activeRoute: ActivatedRoute, private router: Router, private ps: PeriodService) { }

  ngOnInit() {
    this.activeRoute.data.subscribe((d: { period: Period, periods: Period[], employee: Employee }) => {
      this.period = d.period;
      this.periods = d.periods;
      this.employee = d.employee;
      this.callsTitle = d.employee.displayName + '\'s Calls';

      this.refreshData();
    });
  }

  refreshData() {
    this.getCompletedCalls();
    this.callLog.getMyBookedForPeriod(this.employee.id, this.period.id).subscribe(data => this.bookedCount = data);
    this.callLog.getMyCalledForPeriod(this.employee.id, this.period.id).subscribe(data => this.calledCount = data);
  }

  getCompletedCalls() {
    this.loading = true;
    this.callLog.getMyCalledGuestsListForPeriod(this.employee.id, this.period.id).subscribe(g => {
      this.emptyList = true;
      this.guests = g;
      if (this.guests.length) {
        this.emptyList = false;
      }
      this.loading = false;
    });
  }

  changePeriod(p: string) {
    this.ps.getItem(p).subscribe(d => {
      this.period = d;

      this.getCompletedCalls();
    });
  }

  goBack() {
    this.router.navigate(['/mgmt/calllog/' + this.period.id]);
  }
}
