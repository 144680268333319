import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Employee } from 'src/app/models/employee.model';
import { Router } from '@angular/router';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})
export class EmployeeFormComponent implements OnInit {

  @Input() employee: Employee;
  @Input() employeeForm: FormGroup;
  @Output() submitChanges = new EventEmitter<Employee>();
  @Output() submitNewEmployee = new EventEmitter<Employee>();
  @Input() isNew: boolean;

  showRemovePin: boolean;

  constructor(private router: Router, private es: EmployeeService) { }

  ngOnInit() {
    if (this.employee && !this.employee.isActive) {
      this.showRemovePin = true;
    }
  }

  submit() {
    this.employee.firstName = this.employeeForm.value.firstName;
    this.employee.lastName = this.employeeForm.value.lastName;
    this.employee.isManager = this.employeeForm.value.isManager;
    this.employee.isActive = this.employeeForm.value.isActive;
    this.employee.displayName = this.employeeForm.value.firstName + ' ' + this.employeeForm.value.lastName;
    this.submitChanges.emit(this.employee);
  }

  submitNew() {
    this.employee.firstName = this.employeeForm.value.firstName;
    this.employee.lastName = this.employeeForm.value.lastName;
    this.employee.isManager = this.employeeForm.value.isManager;
    this.employee.isActive = this.employeeForm.value.isActive;
    this.employee.displayName = this.employeeForm.value.firstName + ' ' + this.employeeForm.value.lastName;
    this.employee.shouldList = true;
    this.employee.isManager = false;
    this.submitNewEmployee.emit(this.employee);
  }

  changeActive(eve: any) {
    console.log('flipped:' + eve);
    this.showRemovePin = !this.showRemovePin;
  }

  removePin() {
    this.es.pinRemove(this.employee.id);
  }

  managePin() {
    this.router.navigate(['mgmt/employees/pin/' + this.employee.id]);
  }

  goBack() {
    this.router.navigate(['/mgmt/employees']);
  }
}
