import { Injectable } from '@angular/core';
import { ClosingNumber } from 'src/app/models/closing-number.model';
import { Observable, of, EMPTY } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Resolve } from '@angular/router';
import { take, mergeMap } from 'rxjs/operators';
import { EmployeeService } from '../employee.service';

@Injectable({
  providedIn: 'root'
})
export class ClosingNumbersResolverService implements Resolve<ClosingNumber[]> {

  constructor(private emps: EmployeeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClosingNumber[]> | Observable<never> {

    return this.emps.getClosingNumbers().pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
