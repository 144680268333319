import { Component, OnInit } from '@angular/core';
import { Employee } from 'src/app/models/employee.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { MessageModel } from 'src/app/models/message.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-mgmt-employee-edit',
  templateUrl: './mgmt-employee-edit.component.html',
  styleUrls: ['./mgmt-employee-edit.component.css']
})
export class MgmtEmployeeEditComponent implements OnInit {

  employee: Employee;
  employeeId: string;
  employeeForm: FormGroup;
  isNew: Boolean;
  loading: Boolean;
  loadingMsg = 'Loading Employee Info...';

  constructor(private employeeService: EmployeeService, private actRoute: ActivatedRoute,
    private router: Router, private fb: FormBuilder, private constants: ConstantsService) { }

  ngOnInit() {
    this.loading = true;
    this.createForm();

    this.actRoute.paramMap.subscribe(p => {
      this.employeeId = p.get('id');
      if (this.employeeId === 'new') {
        this.loading = false;
        this.isNew = true;
        this.employee = new Employee('', '', '', 'New Employee', '', true);
        this.editEmployee();
      } else {
        this.loading = true;
        this.employeeService.get(this.employeeId).subscribe(d => {
          this.isNew = false;
          this.employee = d;
          this.editEmployee();
          this.loading = false;
        });
      }

    });
  }

  createForm() {
    this.employeeForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      isActive: [],
      isManager: []
    });
  }

  editEmployee() {
    this.employeeForm.patchValue({
      firstName: this.employee.firstName,
      lastName: this.employee.lastName,
      isActive: this.employee.isActive,
      isManager: this.employee.isManager
    });
  }

  backToList() {
    this.router.navigate(['/mgmt/employees']);
  }

  createNewEmployee(emp: Employee) {
    this.loading = true;
    this.loadingMsg = 'Adding employee...';
    this.employeeService.add(emp).subscribe((e: any) => {
      this.constants.sendMessage.next(new MessageModel('Employee Added!', 'alert-success'));
      console.log(JSON.stringify(e));
      this.employee = e;
      this.isNew = false;
      this.loading = false;
    });
  }

  updateEmployee(emp: Employee) {
    this.loading = true;
    this.loadingMsg = 'Updating employee...';
    this.employeeService.update(emp).subscribe(() => {
      this.constants.sendMessage.next(new MessageModel('Employee Updated!', 'alert-success'));
      this.loading = false;
      this.backToList();
    });
  }
}
