import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-calls-counts',
  templateUrl: './calls-counts.component.html',
  styleUrls: ['./calls-counts.component.css']
})
export class CallsCountsComponent implements OnInit {

  @Input() bookedCount: number;
  @Input() calledCount: number;
  @Input() title: string;

  constructor() { }

  ngOnInit() {

  }

}
