import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from 'src/app/models/employee.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  @Input() data: Employee[];
  @Input() filterActive: boolean;

  constructor(private router: Router) { }

  ngOnInit() {

  }

  editEmployee(emp: Employee) {
    this.router.navigate(['mgmt/employees/' + emp.id]);
  }
}
