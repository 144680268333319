import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddOnSale } from 'src/app/models/addon-sale.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-addons-list',
  templateUrl: './addons-list.component.html',
  styleUrls: ['./addons-list.component.css']
})
export class AddonsListComponent implements OnInit {

  @Input() addOnSales: AddOnSale[];
  @Input() title: string;
  @Input() loading: boolean;
  @Input() emptyList: boolean;
  @Input() loadingMessage: string;
  @Input() verifyForm: FormGroup;

  @Output() cancelItem = new EventEmitter<AddOnSale>();
  @Output() verifyItem = new EventEmitter<AddOnSale>();

  selectedAddOn: AddOnSale;
  @Input() showRemoved: boolean;
  @Input() showVerified: boolean;
  @Input() showActions: boolean;

  constructor() { }

  ngOnInit() {

  }

  verifyAddOn(item: AddOnSale) {
    this.selectedAddOn = item;
  }

  updateItem(item: AddOnSale) {
    this.selectedAddOn = null;
    this.verifyItem.emit(item);
  }

  cancelAddOn(itemId: AddOnSale) {
    this.cancelItem.emit(itemId);
  }

  viewCheck(checkUri: string) {
    window.open(checkUri);
  }
}
