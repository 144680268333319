import { Component, OnInit } from '@angular/core';
import { ClosingNumber } from 'src/app/models/closing-number.model';
import { Period } from 'src/app/models/period.model';
import { ActivatedRoute } from '@angular/router';
import { ConstantsService } from 'src/app/services/constants.service';
import { MessageModel } from 'src/app/models/message.model';
import { StoreStat } from 'src/app/models/store-stat.model';
import { StoreStatsService } from 'src/app/services/store-stats.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  closingRanks: ClosingNumber[];
  period: Period;
  emptyClosingList = true;
  loading: boolean;
  storeStat: StoreStat;
  statsAvailable: boolean;

  constructor(private actRoute: ActivatedRoute, private ss: StoreStatsService, private constants: ConstantsService) { }

  ngOnInit() {
    this.statsAvailable = true;
    this.actRoute.data.subscribe(
      (d: { closingRanks: ClosingNumber[], period: Period }) => {
        let msgs = '';
        if (!d.period) {
          msgs += 'Could Not Get Current Period Details!<br />';
        }

        if (!d.closingRanks) {
          msgs += 'Could not get Closing Numbers!';
        }

        if (msgs) {
          this.constants.sendMessage.next(new MessageModel(msgs, 'alert-danger'));
          return;
        }

        this.period = d.period;
        this.closingRanks = d.closingRanks;
        this.getStoreStatsForPeriod();
        if (this.closingRanks.length > 0) {
          this.emptyClosingList = false;
        } else {
          this.emptyClosingList = true;
        }
      }
    );
  }

  getStoreStatsForPeriod() {
    this.ss.getForPeriod().subscribe(d => {
      if (d) {
        this.statsAvailable = true;
      } else {
        this.statsAvailable = false;
      }

      this.storeStat = d;
    });
  }
}
