import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';
import { CallLogService } from 'src/app/services/call-log.service';
import { Rank } from 'src/app/models/rank.model';

@Component({
  selector: 'app-calls-scoreboard',
  templateUrl: './calls-scoreboard.component.html',
  styleUrls: ['./calls-scoreboard.component.css']
})
export class CallsScoreboardComponent implements OnInit {

  rankedEmployees: Rank[];
  bookedCount: number;
  calledCount: number;

  constructor(private constants: ConstantsService, private callLog: CallLogService) { }

  ngOnInit() {
    this.callLog.getBookedCount().subscribe(b => this.bookedCount = b);
    this.callLog.getCurrentCount().subscribe(c => this.calledCount = c);
    this.callLog.getRanking().subscribe(r => this.rankedEmployees = r);
  }

}
