import { Component, OnInit } from '@angular/core';
import { Guest } from 'src/app/models/guest.model';
import { CallLogService } from 'src/app/services/call-log.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-calls-complete',
  templateUrl: './calls-complete.component.html',
  styleUrls: ['./calls-complete.component.css']
})
export class CallsCompleteComponent implements OnInit {

  isCalledList = true;
  guests: Guest[];
  listTitle = 'My Completed For Period';
  calledCount: number;
  bookedCount: number;
  callsTitle = 'My Monthly Calls';
  loading = true;
  emptyList = true;
  empId: string;

  constructor(private callLog: CallLogService, private auth: AuthService) { }

  ngOnInit() {
    const e = this.auth.getPinnedUser();
    this.empId = e.id;
    this.getCompletedCalls();
    this.callLog.getMyBookedForPeriod(this.empId).subscribe(data => this.bookedCount = data);
    this.callLog.getMyCalledForPeriod(this.empId).subscribe(data => this.calledCount = data);
  }

  updateItem(item: any) {

    this.getCompletedCalls();
  }

  getCompletedCalls() {
    this.callLog.getMyCalledGuestsListForPeriod(this.empId).subscribe(g => {
      this.emptyList = true;
      this.guests = g;
      if (this.guests.length) {
        this.emptyList = false;
      }
      this.loading = false;
    });
  }
}
