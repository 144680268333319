import { BaseModel } from './base.model';
import { Employee } from './employee.model';

export class NewMember extends BaseModel {
  constructor(
    id?: string,
    employeeId?: string,
    employee?: Employee,
    memberFirstName?: string,
    memberLastName?: string,
    memberEmail?: string,
    displayName?: string,
    prepaid?: boolean,
    joinDate?: string
  ) {
    super(id);
    this.employeeId = employeeId;
    this.employee = employee;
    this.memberFirstName = memberFirstName;
    this.memberLastName = memberLastName;
    this.displayName = displayName;
    this.memberEmail = memberEmail;
    this.prepaid = prepaid;
    this.joinDate = joinDate;
  }

  employeeId: string;
  employee: Employee;
  memberFirstName: string;
  memberLastName: string;
  displayName: string;
  memberEmail: string;
  prepaid: boolean;
  joinDate: string;
}
