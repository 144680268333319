import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.css']
})
export class NavTopComponent implements OnInit {

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }

  isPinnedIn(): boolean {
    return this.auth.isPinned();
  }
}
