import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { DataSegment } from '../models/data-segment.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.qa';

@Injectable({
  providedIn: 'root'
})
export class DataSegmentService extends BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService) {
    super(http, constants, constants.api_data_segment);
  }

  getCallResults(): Observable<DataSegment[]> {
    return this.http.get<DataSegment[]>(this.constants.api_data_segment + '/' + this.constants.data_segment_call_result + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getCustomerTypes(): Observable<DataSegment[]> {
    return this.http.get<DataSegment[]>(this.constants.api_data_segment + '/' + this.constants.data_segment_customer_type + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getAddOns(): Observable<DataSegment[]> {
    return this.http.get<DataSegment[]>(this.constants.api_data_segment + '/' + this.constants.data_segment_add_on + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
}
