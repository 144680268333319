import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ConstantsService } from '../services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private constants: ConstantsService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    return this.checkManager(url, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const url = `/$(route.path)`;
    return this.checkManager(url, null);
  }

  checkManager(url: string, state: RouterStateSnapshot) {
    if (this.authService.isManager()) {
      return true;
    }

    if (state) {
      this.router.navigate([this.constants.redirectHome], { queryParams: { returnUrl: state.url } });
    } else {
      this.router.navigate([this.constants.redirectHome]);
    }

    return false;
  }
}
