import { Injectable } from '@angular/core';
import { StoreStat } from 'src/app/models/store-stat.model';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { StoreStatsService } from '../store-stats.service';

@Injectable({
  providedIn: 'root'
})
export class StoreStatPeriodResolverService implements Resolve<StoreStat> {

  constructor(private svc: StoreStatsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StoreStat> | Observable<never> {
    const id = route.paramMap.get('periodId');

    return this.svc.getForPeriod(id).pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
