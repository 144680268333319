import { BaseModel } from './base.model';
import { Employee } from './employee.model';
import { DataSegment } from './data-segment.model';

export class Guest extends BaseModel {
  constructor(
    id?: string,
    firstName?: string,
    lastName?: string,
    displayName?: string,
    phone?: string,
    lastAppointment?: string,
    lastCallResult?: string,
    customerTypeId?: string,
    assignedTo?: string,
    timeCalled?: string,
    isComplete?: boolean,
    assigned?: Employee,
    customerType?: DataSegment,
    prepaids?: number
  ) {
    super(id);

    this.firstName = firstName;
    this.lastName = lastName;
    this.displayName = displayName;
    this.phone = phone;
    this.lastAppointment = lastAppointment;
    this.lastCallResult = lastCallResult;
    this.customerTypeId = customerTypeId;
    this.assignedTo = assignedTo;
    this.timeCalled = timeCalled;
    this.isComplete = isComplete;
    this.assigned = assigned;
    this.customerType = customerType;
    this.prepaids = prepaids;
  }

  firstName: string;
  lastName: string;
  displayName: string;
  phone: string;
  lastAppointment: string;
  lastCallResult: string;
  customerTypeId: string;
  assignedTo: string;
  timeCalled: string;
  isComplete: boolean;
  assigned: Employee;
  customerType: DataSegment;
  prepaids: number;
}
