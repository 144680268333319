import { FormControl } from '@angular/forms';
import * as moment from 'moment';

// https://stackoverflow.com/questions/51905033/pre-populating-and-validating-date-in-angular-6-reactive-form
export function FutureDateValidator(): any {
  return (control: FormControl): { [key: string]: any } => {
    // const val = moment(control.value, format, true);
    const y = moment().subtract(14, 'days');
    const i = moment(control.value, 'YYYY-MM-DD', true);
    const max = moment().add(3, 'months');

    // console.log('dates: ');
    // console.log(i < y);
    // console.log(JSON.stringify(i) + ' ' + JSON.stringify(y));

    // if ((i < y)) {
    if (!i.isBetween(y, max)) {
      return { invalidDate: true };
    }

    return null;
  };
}
