import { Component, OnInit, Input, Injectable, Output, EventEmitter } from '@angular/core';
import { Guest } from 'src/app/models/guest.model';
import { ConstantsService } from 'src/app/services/constants.service';
import { AuthService } from 'src/app/services/auth.service';
import { CallLogService } from 'src/app/services/call-log.service';
import { MessageModel } from 'src/app/models/message.model';

@Component({
  selector: 'app-call-list-view',
  templateUrl: './call-list-view.component.html',
  styleUrls: ['./call-list-view.component.css']
})

export class CallListViewComponent implements OnInit {

  @Input() tableData: any[];
  @Input() filter: string;
  @Input() title: string;
  @Input() calledList: boolean;
  @Input() emptyList: boolean;
  @Input() loading: boolean;
  @Output() assignCalls = new EventEmitter();
  @Output() updateCallStatus = new EventEmitter();

  loadingMessage = 'Loading Guests';
  currentGuest: Guest;
  script: string;
  closeResult: string;
  currentItem: Guest;
  addSuccess = false;

  constructor(public constants: ConstantsService, private authService: AuthService,
    private logService: CallLogService) { }

  ngOnInit() {

  }

  callGuest(guest: Guest): void {
    this.currentGuest = guest;
    const employee = this.authService.getPinnedUser();

    switch (this.filter) {
      case this.constants.guest_partitions_declinedEft:
        this.script = this.constants.getEftDeclineScript(guest.firstName, employee.firstName);
        break;
      case this.constants.guest_partitions_36mo:
        this.script = this.constants.get3MoScript(guest.firstName, employee.firstName);
        break;
      case this.constants.guest_partitions_delinquent:
        this.script = this.constants.getDelinquentScript(guest.firstName, employee.firstName, guest.prepaids / 2);
        break;
      default:
        this.script = this.constants.getGuestCallScript(guest.firstName, employee.firstName, guest.lastAppointment);
    }
    // if (this.filter === this.constants.guest_partitions_declinedEft) {
    //   this.script = this.constants.getEftDeclineScript(guest.firstName, employee.firstName);
    // } else if (this.filter === this.constants.guest_partitions_1yr) {
    //   this.script = this.constants.getGuestCallScript(guest.firstName, employee.firstName, guest.lastAppointment);
    // }
  }

  openAcct(guestId: string) {
    const uri = this.constants.venge_acct_path + guestId;
    window.open(uri);
  }

  closeCall() {
    this.currentGuest = null;
    this.script = null;
  }

  getCallList(): void {
    this.assignCalls.emit(true);
  }

  setCallStatus(g: Guest) {
    // Call Api and Close modal

    // remove from Hash
    const emp = this.authService.getPinnedUser();
    this.logService.logCall(g.lastCallResult, this.currentGuest, emp).subscribe(() => {
      this.updateCallStatus.emit(g);
      this.closeCall();
      this.constants.sendMessage.next(new MessageModel('Logged Call for "<b>' + g.displayName + '</b>"', 'alert-success'));
      this.logService.changes.next(true); // Update left Navigation...
    }, err => {
      this.constants.sendMessage.next(new MessageModel('There was an issue logging the call for "<b>'
        + g.displayName + '</b>"', 'alert-danger'));
    });

  }

}
