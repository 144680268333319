import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataSegment } from 'src/app/models/data-segment.model';
import { AddOnSale } from 'src/app/models/addon-sale.model';
import { AuthService } from 'src/app/services/auth.service';
import { AddonSalesService } from 'src/app/services/addon-sales.service';
import { ActivatedRoute } from '@angular/router';
import { ConstantsService } from 'src/app/services/constants.service';
import { MessageModel } from 'src/app/models/message.model';
import { Employee } from 'src/app/models/employee.model';
import { FutureDateValidator } from 'src/app/validators/future-date-validator';
import { CheckUrlValidator } from 'src/app/validators/check-url-validator';
import { DateValidator } from 'src/app/validators/date-validator';

@Component({
  selector: 'app-addons-view',
  templateUrl: './addons-view.component.html',
  styleUrls: ['./addons-view.component.css']
})
export class AddonsViewComponent implements OnInit {

  addOnForm: FormGroup;
  addOns: DataSegment[];
  addOnSales: AddOnSale[];
  emptyList: boolean;
  loading: boolean;
  loadingMessage: string;
  filter: string;
  listTitle: string;
  showActions: boolean;
  showRemoved: boolean;
  showVerified: boolean;
  showForm: boolean;
  verifyForm: FormGroup;

  constructor(private fb: FormBuilder, private auth: AuthService, private constants: ConstantsService,
    private addOnService: AddonSalesService, private actRouter: ActivatedRoute) { }

  ngOnInit() {
    this.emptyList = true;
    this.loading = true;
    this.loadingMessage = 'Loading Addons...';
    this.filter = '';
    this.showForm = true;

    this.createForm();
    this.createVerifyForm();
    this.actRouter.paramMap.subscribe(p => {
      this.filter = p.get('filter');
      this.loadItems();
    });

    this.actRouter.data.subscribe((d: { addOns: DataSegment[], sales: AddOnSale[] }) => {
      this.addOns = d.addOns;
    });
  }

  filterItems() {
    switch (this.filter) {
      case 'verified':
        this.showActions = false;
        this.showRemoved = false;
        this.showVerified = true;
        this.showForm = false;
        break;
      case 'removed':
        this.showActions = false;
        this.showRemoved = true;
        this.showVerified = false;
        this.showForm = false;
        if (this.auth.isAdmin()) {
          this.showActions = true;
        }
        break;
      case 'expired':
        this.showActions = false;
        this.showRemoved = false;
        this.showVerified = false;
        this.showForm = false;
        if (this.auth.isAdmin()) {
          this.showActions = true;
        }
        break;
      default:
        this.showActions = true;
        this.showRemoved = false;
        this.showVerified = false;
        this.showForm = true;
    }
  }

  loadItems() {
    this.loading = true;
    this.filterItems();
    switch (this.filter) {
      case 'today':
        this.getTodaysItems();
        break;
      case 'verified':
        this.getVerified();
        break;
      case 'removed':
        this.getRemoved();
        break;
      case 'expired':
        this.getExpired();
        break;
      default:
        this.getAll();
    }
  }

  getAll() {
    this.loadingMessage = 'Loading All Open AddOns...';
    this.listTitle = 'Open AddOns';
    this.addOnService.getAll().subscribe(d => {
      this.addOnSales = d;
      if (d && d.length > 0) {
        this.emptyList = false;
      } else {
        this.emptyList = true;
      }
      this.listTitle += ' (' + this.addOnSales.length + ')';
      this.loading = false;
    },
      err => {
        this.constants.sendMessage.next(new MessageModel('Error Loading Add On Sales...', 'alert-danger'));
        this.loading = false;
      });
  }

  getFilteredItems() {
    this.addOnService.getByFilter(this.filter).subscribe(d => {
      this.addOnSales = d;
      if (d && d.length > 0) {
        this.emptyList = false;
      } else {
        this.emptyList = true;
      }
      this.listTitle += ' (' + this.addOnSales.length + ')';
      this.loading = false;
    },
      () => {
        this.constants.sendMessage.next(new MessageModel('Error Loading Add On Sales...', 'alert-danger'));
        this.loading = false;
      });
  }

  getTodaysItems() {
    this.loadingMessage = 'Loading Today\'s AddOns...';
    this.listTitle = 'Today\'s AddOns';
    this.getFilteredItems();
  }

  getVerified() {
    this.loadingMessage = 'Loading Verified AddOns...';
    this.listTitle = 'Verified';
    this.getFilteredItems();
  }

  getRemoved() {
    this.loadingMessage = 'Loading Removed AddOns...';
    this.listTitle = 'Removed AddOns';
    this.getFilteredItems();
  }

  getExpired() {
    this.loadingMessage = 'Loading Expired AddOns...';
    this.listTitle = 'Expired AddOns';
    this.getFilteredItems();
  }

  createVerifyForm() {
    this.verifyForm = this.fb.group({
      checkId: ['', [Validators.required, Validators.minLength(46), CheckUrlValidator()]]
    });
  }

  createForm() {
    this.addOnForm = this.fb.group({
      clientName: ['', [Validators.required, Validators.minLength(4)]],
      appointmentDate: ['', [Validators.required, DateValidator(), FutureDateValidator()]],
      addOnId: ['', Validators.required]
    });
  }

  removeFromTable(itemId: string) {
    this.addOnSales.splice(this.addOnSales.findIndex(i => i.id === itemId), 1);
  }

  verifyItem(item: AddOnSale) {
    const e = this.auth.getPinnedUser();
    this.addOnService.verify(item, e.id).subscribe(
      a => {
        this.constants.sendMessage.next(new MessageModel('Add On Sale Verified!', 'alert-success'));
        this.removeFromTable(a.id);
        this.verifyForm.reset();
      },
      () => { this.constants.sendMessage.next(new MessageModel('Could not Update the item!', 'alert-danger')); }
    );
  }

  removeItem(item: AddOnSale) {
    if (!item) {
      this.constants.sendMessage.next(new MessageModel('No Item Selected!', 'alert-danger'));
      return;
    }
    const e = this.auth.getPinnedUser();

    if (!e) {
      this.constants.sendMessage.next(new MessageModel('User not Pinned In!', 'alert-danger'));
      return;
    }

    item.verifierId = e.id;

    this.addOnService.removeItem(item).subscribe(
      () => {
        // this.addOnSales.splice(this.addOnSales.findIndex(i => i.id === item.id), 1);
        this.removeFromTable(item.id);
        this.constants.sendMessage.next(new MessageModel('Item Has been sent to the Removed List', 'alert-success'));
      },
      () => {
        this.constants.sendMessage.next(
          new MessageModel('There was an issue removing the item from the list.  Try Again later.', 'alert-danger'));
      }
    );
  }

  submitItem(item: AddOnSale) {
    const e: Employee = this.auth.getPinnedUser();
    item.employeeId = e.id;

    this.addOnService.add(item).subscribe(
      d => {
        this.constants.sendMessage.next(new MessageModel('Saved AddOn Sale', 'alert-success'));
        this.addOnSales.unshift(d);
        this.emptyList = false;
        this.addOnForm.reset();
      },
      err => {
        // console.log(JSON.stringify(err));
        if (err) {
          this.constants.sendMessage.next(new MessageModel(err, 'alert-danger'));
        } else {
          this.constants.sendMessage.next(new MessageModel('There was an error submitting your AddOn Sale!', 'alert-danger'));
        }
      }
    );

  }

}
