import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { AddOnSale } from 'src/app/models/addon-sale.model';
import { AddonSalesService } from '../addon-sales.service';

@Injectable({
  providedIn: 'root'
})
export class AddonSalesResolverService implements Resolve<AddOnSale[]> {

  constructor(private svc: AddonSalesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AddOnSale[]> | Observable<never> {

    return this.svc.getAll().pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
