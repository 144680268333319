import { BaseModel } from './base.model';

export class Employee extends BaseModel {
  constructor(
    id?: string,
    firstName?: string,
    lastName?: string,
    displayName?: string,
    imageUri?: string,
    isActive?: boolean,
    isManager?: boolean,
    shouldList?: boolean
  ) {
    super(id);

    this.firstName = firstName;
    this.lastName = lastName;
    this.displayName = displayName;
    this.imageUri = imageUri;
    this.isActive = isActive;
    this.isManager = isManager;
    this.shouldList = shouldList;
  }

  displayName: string;
  firstName: string;
  lastName: string;
  imageUri: string;
  isActive: boolean;
  isManager: boolean;
  shouldList: boolean;
}
