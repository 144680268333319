import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { MessageModel } from 'src/app/models/message.model';
import { PinQuery } from 'src/app/models/pin-query.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-mgmt-employee-pin-edit',
  templateUrl: './mgmt-employee-pin-edit.component.html',
  styleUrls: ['./mgmt-employee-pin-edit.component.css']
})
export class MgmtEmployeePinEditComponent implements OnInit {

  employee: Employee;
  requestor: Employee;
  employeeId: string;
  employeePinForm: FormGroup;
  loading: boolean;
  loadingMsg = 'Loading Employee Pin Info...';

  constructor(private employeeService: EmployeeService, private authService: AuthService, private actRoute: ActivatedRoute,
    private router: Router, private fb: FormBuilder, private constants: ConstantsService) { }

  ngOnInit() {
    this.loading = true;
    this.createForm();

    this.actRoute.paramMap.subscribe(p => {
      this.employeeId = p.get('id');
      this.loading = true;
      this.employeeService.get(this.employeeId).subscribe(d => {
        this.employee = d;
        this.loading = false;
      });
    });
  }

  createForm() {
    this.employeePinForm = this.fb.group({
      pin: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(6)]]
    });
  }

  backToList() {
    this.router.navigate(['/mgmt/employees/']);
  }

  updatePin(pq: PinQuery) {
    const e = this.authService.getPinnedUser();
    pq.requestorId = e.id;
    this.loading = true;
    this.loadingMsg = 'Updating Pin...';
    this.employeeService.pinUpsert(pq.id, pq).subscribe(() => {
      this.constants.sendMessage.next(new MessageModel('Pin Updated', 'alert-success'));
      this.loading = false;
      this.backToList();
    });
  }
}
