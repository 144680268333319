import { Employee } from './employee.model';

export class WaitList {
  constructor(id?: string,
    clientName?: string,
    phone?: string,
    appointmentDate?: string,
    appointmentTime?: string,
    requestDetails?: string,
    employeeId?: string,
    created?: Date,
    employee?: Employee) {

    this.id = id;
    this.clientName = clientName;
    this.phone = phone;
    this.appointmentDate = appointmentDate;
    this.appointmentTime = appointmentTime;
    this.requestDetails = requestDetails;
    this.created = created;
    this.employeeId = employeeId;
    this.employee = employee;
  }

  id: string;
  clientName: string;
  phone: string;
  appointmentDate: string;
  appointmentTime: string;
  requestDetails: string;
  created: Date;
  employee: Employee;
  employeeId: string;

}
