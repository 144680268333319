import { Component, OnInit, Input } from '@angular/core';
import { CallLogService } from 'src/app/services/call-log.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { Rank } from 'src/app/models/rank.model';

@Component({
  selector: 'app-calls-booked-list-view',
  templateUrl: './calls-booked-list-view.component.html',
  styleUrls: ['./calls-booked-list-view.component.css']
})
export class CallsBookedListViewComponent implements OnInit {

  @Input() employeesRanked: Rank[];

  constructor(private callLog: CallLogService, private constants: ConstantsService) { }

  ngOnInit() {
    this.callLog.getRanking().subscribe(r => this.employeesRanked = r);
  }

}
