import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'src/app/components/page-not-found/page-not-found.component';
import { HomeComponent } from 'src/app/components/home/home.component';
import { CallsMyComponent } from 'src/app/components/calls/calls-my/calls-my.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { MgmtComponent } from 'src/app/components/mgmt/mgmt-view/mgmt.component';
import { PinGuard } from 'src/app/guards/pin.guard';
import { CallsCompleteComponent } from 'src/app/components/calls/calls-complete/calls-complete.component';
import { AddonsViewComponent } from 'src/app/components/addons/addons-view/addons-view.component';
import { WaitlistViewComponent } from 'src/app/components/waitlist/waitlist-view/waitlist-view.component';
import { ManagerGuard } from 'src/app/guards/manager.guard';
import { NavCallsComponent } from 'src/app/components/nav/nav-calls/nav-calls.component';
import { MgmtNavComponent } from 'src/app/components/nav/mgmt-nav/mgmt-nav.component';
import { ViewBaseComponent } from 'src/app/components/view-base/view-base.component';
import { MgmtPitchesComponent } from 'src/app/components/mgmt/mgmt-pitches/mgmt-pitches.component';
import { MgmtCallLogComponent } from 'src/app/components/mgmt/mgmt-call-log/mgmt-call-log.component';
import { MgmtEmployeesComponent } from 'src/app/components/mgmt/mgmt-employees/mgmt-employees.component';
import { MgmtCallLogDetailsComponent } from 'src/app/components/mgmt/mgmt-call-log-details/mgmt-call-log-details.component';
import { MgmtEmployeeEditComponent } from 'src/app/components/mgmt/mgmt-employee-edit/mgmt-employee-edit.component';
import { MgmtPitchesEditComponent } from 'src/app/components/mgmt/mgmt-pitches-edit/mgmt-pitches-edit.component';
import { EmployeeResolverService } from 'src/app/services/resolvers/employee-resolver.service';
import { ClosingNumbersResolverService } from 'src/app/services/resolvers/closing-numbers-resolver.service';
import { PeriodsResolverService } from 'src/app/services/resolvers/periods-resolver.service';
import { PeriodsCurrentResolverService } from 'src/app/services/resolvers/periods-current-resolver.service';
import { ClosingNumbersEmployeePeriodService } from 'src/app/services/resolvers/closing-numbers-employee-period.service';
import { MgmtStoreStatsComponent } from 'src/app/components/mgmt/mgmt-store-stats/mgmt-store-stats.component';
import { PeriodResolverService } from 'src/app/services/resolvers/period-resolver.service';
import { StoreStatPeriodResolverService } from 'src/app/services/resolvers/store-stat-period-resolver.service';
import { NavAddonsComponent } from 'src/app/components/nav/nav-addons/nav-addons.component';
import { AddonItemsResolverService } from 'src/app/services/resolvers/addon-items-resolver.service';
import { MgmtAddonsComponent } from 'src/app/components/mgmt/mgmt-addons/mgmt-addons.component';
import { PeriodsPayResolverService } from 'src/app/services/resolvers/periods-pay-resolver.service';
import { PeriodPayResolverService } from 'src/app/services/resolvers/period-pay-resolver.service';
import { AddonsMyComponent } from 'src/app/components/addons/addons-my/addons-my.component';
import { AddonsMyPendingComponent } from 'src/app/components/addons/addons-my-pending/addons-my-pending.component';
import { MgmtAddonsDetailsComponent } from 'src/app/components/mgmt/mgmt-addons-details/mgmt-addons-details.component';
import { AddonsBoardComponent } from 'src/app/components/addons/addons-board/addons-board.component';
import { DfrViewComponent } from 'src/app/components/mgmt/dfr/dfr-view/dfr-view.component';
import { DfrEditComponent } from 'src/app/components/mgmt/dfr/dfr-edit/dfr-edit.component';
import { DfrItemResolverService } from 'src/app/services/resolvers/dfr-item-resolver.service';
import { MgmtEmployeePinEditComponent } from 'src/app/components/mgmt/mgmt-employee-pin-edit/mgmt-employee-pin-edit.component';
import { NewMembersViewComponent } from 'src/app/components/new-members/new-members-view/new-members-view.component';
import { NewMemberFormComponent } from 'src/app/components/new-members/new-member-form/new-member-form.component';
import { NewMemberEditComponent } from 'src/app/components/new-members/new-member-edit/new-member-edit.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home', component: ViewBaseComponent, canActivate: [AuthGuard], children: [
      {
        path: '', component: HomeComponent,
        resolve: { closingRanks: ClosingNumbersResolverService, period: PeriodsCurrentResolverService }
      }
    ]
  },
  {
    path: 'calls', component: ViewBaseComponent, canActivate: [AuthGuard, PinGuard], children: [
      { path: '', component: CallsMyComponent },
      { path: '', outlet: 'sidenav', component: NavCallsComponent },
      { path: 'completed', component: CallsCompleteComponent, canActivate: [AuthGuard, PinGuard] },
      { path: ':customerTypeId', component: CallsMyComponent, canActivate: [AuthGuard, PinGuard] },
    ]
  },
  {
    path: 'mgmt', component: ViewBaseComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard], children: [
      { path: '', redirectTo: '/mgmt/pitches', pathMatch: 'full' },
      { path: '', outlet: 'sidenav', component: MgmtNavComponent },
      {
        path: 'pitches',
        component: MgmtPitchesComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { period: PeriodsCurrentResolverService, periods: PeriodsResolverService }
      },
      {
        path: 'pitches/:periodId',
        component: MgmtPitchesComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { period: PeriodResolverService, periods: PeriodsResolverService }
      },
      {
        path: 'pitches/:periodId/:id',
        component: MgmtPitchesEditComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { closingNumber: ClosingNumbersEmployeePeriodService, period: PeriodResolverService, employee: EmployeeResolverService }
      },
      {
        path: 'stats/:periodId',
        component: MgmtStoreStatsComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { period: PeriodResolverService, storeStat: StoreStatPeriodResolverService }
      },
      {
        path: 'calllog',
        component: MgmtCallLogComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { period: PeriodsCurrentResolverService, periods: PeriodsResolverService }
      },
      {
        path: 'calllog/:periodId',
        component: MgmtCallLogComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { period: PeriodResolverService, periods: PeriodsResolverService }
      },
      {
        path: 'calllog/:periodId/:id',
        component: MgmtCallLogDetailsComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { period: PeriodResolverService, periods: PeriodsResolverService, employee: EmployeeResolverService }
      },
      { path: 'employees', component: MgmtEmployeesComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard] },
      {
        path: 'employees/:id',
        component: MgmtEmployeeEditComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard]
      },
      {
        path: 'employees/new',
        component: MgmtEmployeeEditComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard]
      },
      {
        path: 'employees/pin/:id',
        component: MgmtEmployeePinEditComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard]
      },
      {
        path: 'addons',
        component: MgmtAddonsComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { monthPeriods: PeriodsResolverService, payPeriods: PeriodsPayResolverService }
      },
      {
        path: 'addons/:filter',
        component: MgmtAddonsComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { monthPeriods: PeriodsResolverService, payPeriods: PeriodsPayResolverService }
      },
      {
        path: 'addons/:filter/:periodId/:id',
        component: MgmtAddonsDetailsComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: {
          monthPeriods: PeriodsResolverService, payPeriods: PeriodsPayResolverService,
          period: PeriodResolverService, employee: EmployeeResolverService
        }
      },
      {
        path: 'dfrs',
        component: DfrViewComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { periods: PeriodsResolverService, period: PeriodsCurrentResolverService }
      },
      {
        path: 'dfrs/edit',
        component: DfrEditComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard]
      },
      {
        path: 'dfrs/edit/:dfrId',
        component: DfrEditComponent, canActivate: [AuthGuard, PinGuard, ManagerGuard],
        resolve: { dfrId: DfrItemResolverService }
      }
    ]
  },
  {
    path: 'addons', component: ViewBaseComponent, canActivate: [AuthGuard, PinGuard], children: [
      {
        path: '',
        component: AddonsViewComponent,
        resolve: { addOns: AddonItemsResolverService }
      },
      { path: '', outlet: 'sidenav', component: NavAddonsComponent },
      {
        path: 'my',
        component: AddonsMyComponent,
        resolve: { periods: PeriodsPayResolverService, period: PeriodPayResolverService }
      },
      {
        path: 'my/pending',
        component: AddonsMyPendingComponent
      },
      {
        path: 'my/:periodId',
        component: AddonsMyComponent,
        resolve: { periods: PeriodsPayResolverService, period: PeriodPayResolverService }
      },
      {
        path: 'board',
        component: AddonsBoardComponent,
        resolve: { period: PeriodPayResolverService }
      },
      {
        path: ':filter',
        component: AddonsViewComponent,
        resolve: { addOns: AddonItemsResolverService }
      }
    ]
  },
  {
    path: 'waitlist', component: ViewBaseComponent, canActivate: [AuthGuard, PinGuard], children: [
      { path: '', component: WaitlistViewComponent }
    ]
  },
  {
    path: 'nm', component: ViewBaseComponent, canActivate: [AuthGuard, PinGuard], children: [
      { path: '', component: NewMembersViewComponent },
      { path: ':id', component: NewMemberEditComponent},
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRouterModule { }
