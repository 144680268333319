import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { Observable } from 'rxjs';
import { DfrItem } from '../models/dfr-item.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DfrService extends BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService) {
    super(http, constants, constants.api_dfrs);
  }

  getDfrsForPeriod(periodId: string): Observable<DfrItem[]> {
    return this.http.get<DfrItem[]>(this.constants.api_dfrs_period +'/' + periodId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
}
