import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { ConstantsService } from './constants.service';
import { Observable } from 'rxjs';
import { StoreStat } from '../models/store-stat.model';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreStatsService extends BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService) {
    super(http, constants, constants.api_store_stats);
  }

  getForPeriod(periodId?: string): Observable<StoreStat> {
    if (periodId) {
      return this.http.get<StoreStat>(this.constants.api_store_stats + '/' + periodId + environment.api_code)
        .pipe(catchError((e: any) => this.handleError(e)));
    }

    return this.http.get<StoreStat>(this.constants.api_store_stats + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
}
