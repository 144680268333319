import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConstantsService } from 'src/app/services/constants.service';
import { WaitListService } from 'src/app/services/wait-list.service';
import { MessageModel } from 'src/app/models/message.model';
import { WaitList } from 'src/app/models/waitlist-model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-waitlist-view',
  templateUrl: './waitlist-view.component.html',
  styleUrls: ['./waitlist-view.component.css']
})
export class WaitlistViewComponent implements OnInit {

  waitForm: FormGroup;
  loading: boolean;
  loadingMessage: string;
  emptyList: boolean;
  waitListItems: any;

  constructor(private fb: FormBuilder, private constants: ConstantsService,
    private auth: AuthService, private waitlistService: WaitListService) { }

  ngOnInit() {
    this.emptyList = true;
    this.loadingMessage = 'Loading Waitlist...';
    this.createForm();
    this.loadItems();
  }

  createForm() {
    this.waitForm = this.fb.group({
      clientName: ['', [Validators.required, Validators.minLength(5)]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(14)]],
      appointmentDate: ['', [Validators.required]],
      appointmentTime: ['', [Validators.required]],
      requestDetails: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  loadItems() {
    this.loading = true;
    this.waitlistService.getAll().subscribe(d => {
      this.waitListItems = d;
      this.loading = false;
      if (d && d.length > 0) {
        this.emptyList = false;
      }
    },
      () => {
        this.loading = false;
        this.emptyList = false;
      });
  }

  removeFromTable(itemId: string) {
    this.waitListItems.splice(this.waitListItems.findIndex(i => i.id === itemId), 1);
    if (this.waitListItems.length < 1){
      this.emptyList = true;
    }
  }

  removeItem(itemId: string) {
    this.waitlistService.complete(itemId).subscribe(
      () => {
        this.removeFromTable(itemId);
        this.constants.sendMessage.next(new MessageModel('Item removed!', 'alert-success'));
      },
      () => {
        this.constants.sendMessage.next(new MessageModel('Error Removing item!', 'alert-danger'));
      }
    );
  }

  updateItem(item: WaitList) {
    const e = this.auth.getPinnedUser();
    item.employeeId = e.id;

    this.waitlistService.add(item).subscribe(
      d => {
        this.constants.sendMessage.next(new MessageModel('Client Added to Wait List', 'alert-success'));
        this.waitListItems.unshift(d);
        this.emptyList = false;
        this.waitForm.reset();
      },
      () => {
        this.constants.sendMessage.next(new MessageModel('There was an issue adding client to list', 'alert-danger'));
      });
  }
}
