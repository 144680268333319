import { Pipe, PipeTransform } from '@angular/core';
import { ConstantsService } from '../services/constants.service';

@Pipe({
  name: 'customerType'
})
export class CustomerTypePipe implements PipeTransform {

  constructor(private constants: ConstantsService) {

  }

  transform(value: any, args?: any): any {

    switch (value) {
      case this.constants.guest_partitions_1yr:
        return '1Yr Guest';
      case this.constants.guest_partitions_36mo:
        return '3 Mo. Guest';
      case this.constants.guest_partitions_declinedEft:
        return 'Declined EFT';
      case this.constants.guest_partitions_delinquent:
        return 'Delinquent';
      default:
        return value;
    }
  }

}
