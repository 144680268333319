import { FormControl } from '@angular/forms';
import * as moment from 'moment';

// https://stackoverflow.com/questions/51905033/pre-populating-and-validating-date-in-angular-6-reactive-form
export function DateValidator(): any {
    return (control: FormControl): { [key: string]: any } => {
        const val = moment(control.value, 'YYYY-MM-DD', true);

        if (!val.isValid()) {
            return { invalidDateFormat: true };
        }

        return null;
    };
}
