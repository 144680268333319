import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Guest } from 'src/app/models/guest.model';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-modal-call-complete',
  templateUrl: './modal-call-complete.component.html',
  styleUrls: ['./modal-call-complete.component.css']
})
export class ModalCallCompleteComponent implements OnInit {

  @Input() guest: Guest;
  @Input() script: string;
  @Output() updateItem = new EventEmitter();
  @Output() returnToList = new EventEmitter();

  isEftDecline: boolean;
  is1YrGuest: boolean;

  constructor(private constants: ConstantsService) { }

  ngOnInit() {
    this.checkCustomerType();
  }

  checkCustomerType() {
    console.log('guest: ' + JSON.stringify(this.guest));
    if (this.guest) {
      switch (this.guest.customerTypeId) {
        case (this.constants.customer_type_1yr):
          this.is1YrGuest = true;
          this.isEftDecline = false;
          break;
        case (this.constants.customer_type_declinedeft):
          this.is1YrGuest = false;
          this.isEftDecline = true;
          break;
        default:
          {
            this.isEftDecline = false;
            this.is1YrGuest = false;
            break;
          }
      }
    }
  }

  setStatus(callResult: string) {
    this.updateItem.emit(callResult);
  }

  backToList() {
    this.returnToList.emit(this.guest);
  }
}
