export class StoreStat {
  constructor(
    id?: string,
    periodId?: string,
    freezes?: number,
    cancels?: number,
    closeRate?: number,
    totalMembers?: number,
    prospects?: number,
    newMembers?: number,
    created? :string,
    updated? :string
  ) {
    this.id = id;
    this.periodId = periodId;
    this.freezes = freezes;
    this.cancels = cancels;
    this.closeRate = closeRate;
    this.totalMembers = totalMembers;
    this.newMembers = newMembers;
    this.prospects = prospects;
    this.created = created;
    this.updated = updated;
  }

  id: string;
  periodId: string;
  freezes: number;
  cancels: number;
  closeRate: number;
  totalMembers: number;
  prospects: number;
  newMembers: number;
  created: string;
  updated: string;
}
