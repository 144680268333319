import { DataSegment } from './data-segment.model';
import { Employee } from './employee.model';

export class AddOnSale {
  constructor(id?: string, employeeId?: string, dateSold?: string, appointmentDate?: string,
    addOnId?: string, clientName?: string, checkId?: string, verified?: boolean,
    verifierId?: string, verifiedOn?: string, periodId?: string, payPeriodId?: string, employee?: Employee, verifier?: Employee,
    addOn?: DataSegment, isComplete?: boolean, usedPrepaids?: boolean, removalReason?: string, status?:string) {

    this.id = id;
    employeeId = employeeId;
    dateSold = dateSold;
    this.appointmentDate = appointmentDate;
    this.addOnId = addOnId;
    this.clientName = clientName;
    this.checkId = checkId;
    this.verified = verified;
    this.verifierId = verifierId;
    this.verifiedOn = verifiedOn;
    this.employee = employee;
    this.periodId = periodId;
    this.payPeriodId = payPeriodId;
    this.verifier = verifier;
    this.addOn = addOn;
    this.isComplete = isComplete;
    this.usedPrepaids = usedPrepaids;
    this.removalReason = removalReason;
    this.status = status;
  }

  id: string;
  employeeId: string;
  dateSold: string;
  appointmentDate: string;
  addOnId: string;
  clientName: string;
  checkId: string;
  verified: boolean;
  verifierId: string;
  verifiedOn: string;
  periodId: string;
  payPeriodId: string;
  isComplete: boolean;
  usedPrepaids: boolean;
  removalReason: string;
  status: string;

  employee: Employee;
  verifier: Employee;
  addOn: DataSegment;
}



