import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessageModel } from '../models/message.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor() { }

  sendMessage: BehaviorSubject<MessageModel> = new BehaviorSubject(new MessageModel());

  public api_base = environment.api_host + 'api/';

  // public api_guests = '/assets/mocks/guests.json';
  public api_guests = this.api_base + 'guests';
  public api_guests_by_partition = this.api_base + 'guests/partition';
  public api_assignGuests = this.api_base + 'assignedGuests';

  public api_quotes = 'https://favqs.com/api/qotd';

  // Employee API
  public api_employees = this.api_base + 'employees';
  public api_employee_closingNumbers = this.api_employees + '/closing';
  public api_employee_scores = this.api_employees + '/scores';
  public api_employee_byPin = this.api_employees + '/pin';
  public api_employee_calls = this.api_employees + '/calls';
  public api_employee_create_call_list = this.api_employees + '/assign';
  public api_employee_call_list_count = this.api_employees + '/assign/count';
  public api_employee_clear_call_list = this.api_employees + '/clearlist';

  // Call Log API
  public api_call_log = this.api_base + 'calllogs';
  public api_call_log_by_status = this.api_call_log + '/status';
  public api_call_log_current_count = this.api_call_log + '/currentcount';
  public api_call_log_for_period = this.api_call_log + '/period';
  public api_call_log_rank = this.api_call_log + '/rank';
  public api_call_log_period_employee_status = this.api_call_log + '/periodemployee';
  public api_call_log_period_employee_called = this.api_call_log + '/periodemployee/count';
  public api_call_log_employee_status = this.api_call_log + '/employee/';
  public api_call_log_employee_called = this.api_call_log + '/employee/count';
  public api_call_log_period_employee_called_list = this.api_call_log + '/periodemployee/list';
  public api_call_log_period_all_counts = this.api_call_log + '/periodemployees';
  public api_call_log_employee_summary = this.api_call_log + '/summary';

  // Period API
  public api_period = this.api_base + 'periods';
  public api_period_item = this.api_period + '/item';
  public api_period_list = this.api_period + '/list';
  public api_period_current = this.api_period + '/current';
  public api_period_create_pitches = this.api_period + '/createPitches';

  // AddOns API
  public api_addon_sales = this.api_base + 'addonsales';
  public api_addon_sales_pay_period = this.api_addon_sales + '/period';
  public api_addon_sales_verify = this.api_addon_sales + '/verify';
  public api_addon_sales_filter = this.api_addon_sales + '/filter';
  public api_addon_sales_summary = this.api_addon_sales + '/summary';

  // DFR API
  public api_dfrs = this.api_base + 'dfrs';
  public api_dfrs_period = this.api_dfrs + '/period';

  // New Member Service
  public api_nm = this.api_base + 'nm';

  public api_update_history = this.api_base + 'updates';

  public api_store_stats = this.api_base + 'stats';

  public api_data_segment = this.api_base + 'datasegments';

  public api_waitlist = this.api_base + 'waitlist';

  // tslint:disable-next-line:variable-name
  public key_access_token = 'access_token';
  // tslint:disable-next-line:variable-name
  public key_token_id = 'id_token';
  // tslint:disable-next-line:variable-name
  public key_expires_at = 'expires_at';
  // tslint:disable-next-line:variable-name
  public key_refresh_token = 'refresh_token';
  // tslint:disable-next-line:variable-name
  public key_scopes = 'scopes';

  public key_pin_user = 'pinUser';

  public redirectLogin = '/login';
  public redirectHome = '/home';
  public venge_acct_path = 'https://luxe.bookbyaliens.com/customers/';

  public guest_partitions_1yr = 'a951972f-0a98-4f55-9fb3-1568f0f08be1';
  public guest_partitions_36mo = 'bd7b17e5-56f3-4955-8ccc-f34a1bf11096';
  public guest_partitions_guest = 'a895bc0a-791b-4d6b-9b98-fcaf42ef217b';
  public guest_partitions_eft = '49bb4b41-8837-41b9-95f6-4947dac74870';
  public guest_partitions_declinedEft = '2f743175-14e4-41ac-a9c6-0c5e25d55c12';
  public guest_partitions_frozen = 'dbb1b8bc-3489-4cae-b0c0-96db4dcbbc1f';
  public guest_partitions_active = '11140e59-95a9-4483-8212-684f8f82edd7';
  public guest_partitions_cancelled = 'de860767-bdf3-4218-a3f8-b75d5fd4214d';
  public guest_partitions_delinquent = 'f3c3f976-c5c4-4307-b7e3-946b9951652d';

  public call_log_booked = 'f0cc94a4-ed40-4102-8c62-b63a68935214';

  public data_segment_customer_type = 'CustomerType';
  public data_segment_add_on = 'AddOns';
  public data_segment_call_result = 'CallResult';

  public customer_type_1yr = '1Yr';
  public customer_type_declinedeft = 'declinedeft';
  public customer_type_successeft = 'successeft';
  public customer_type_guest = 'guest';
  public customer_type_active = 'active';
  public customer_type_frozen = 'frozen';
  public customer_type_cancelled = 'cancelled';

  public period_type_month = 'Month';
  public period_type_pay = 'Pay';

  // Script Placeholders
  public getGuestCallScript(guestName: string, employeeName: string, lastAppointment: string): string {
    return '<p>Hello could I speak with <b>' + guestName + '</b>?</p> <p>This is <b>' + employeeName +
      '</b> from Massageluxe/Faceluxe of Pembroke Pines. How are you today?' +
      '<p>The reason I am calling you is to, one, thank you for trying us out in the past' +
      ' and to offer you a <b><em>60 minute Deep Tissue or Swedish Massage for only $45</em></b>.  ' +
      'I have availability for today or tomorrow?</p>' +
      '<p class="text-center"><b><em>**[Suggest the morning 9am-12am]**</em></b></span></p>' +
      '<h4>No?</h4> Ok no problem, if you change your mind just give us a call as this offer is good til ' +
      'the end of the month.  Have a <em>relaxed</em> day!';
  }

  public getEftDeclineScript(guestName: string, employeeName: string): string {
    return '<p>Hello could I speak with ' + guestName + '</b>?</p> <p>This is <b>' + employeeName +
      '</b> from Massageluxe of Pembroke Pines. I was calling becasue we were not able to collect the dues for your ' +
      'membership.  Is It possible your card expired or changed? ' +
      '<p class="text-center"><b><em>[Pause for response]</b></em><p>Could we update the payment you have on file now?</p>' +
      '<p class="text-center"><b><em>[Collect New Card information AND COLLECT DUES]</em></b></p>' +
      'Ok we have your account updated.  Would you like to book your next appointment?';
  }

  public getEftSuccessScript(guestName: string, employeeName: string): string {
    return '<p>Hello could I speak with ' + guestName + '</b>?</p> <p>This is <b>' + employeeName +
      '</b> from Massageluxe of Pembroke Pines. I was giving you a courtesy call as we saw your monthly ' +
      'massage hour has posted to you account ' +
      'and wanted to see if we can book your next appointment?</p>' +
      '<p class="text-center><em>[Pause for Response]</em></p>' +
      '<p><b><em>** If YES, Suggenst the next morning availability 9-11am, BE ACCOMODATING AND FOLLOW THEIR LEAD!! **</em></b>';
  }

  public get3MoScript(guestName: string, employeeName: string): string {
    return '<p>Hello could I speak with <b>' + guestName + '</b>?</p> <p>This is <b>' + employeeName +
      '</b> from Massageluxe of Pembroke Pines. How are you today?' +
      '<p>The reason I am calling you is to, one, thank you for trying us out in the past' +
      ' and to offer you a second intro offer </p>' +
      '<p class="text-center"><b><em>**[Offer: If they did 60min offer 90min intro, vice versa]**</em></b>.</p>  ' +
      'I have availability for today or tomorrow?</p>' +
      '<p class="text-center"><b><em>**[Suggest the morning 9am-12am if available]**</em></b></p>' +
      '<h4>No?</h4> Ok no problem, if you change your mind just give us a call as this offer is good til ' +
      'the end of the month.  Have a <em>relaxed</em> day!';
  }

  public getDelinquentScript(guestName: string, employeeName: string, prepaids: number): string {
    return '<p>Hello could I speak with ' + guestName + '</b>?</p> <p>This is <b>' + employeeName +
      '</b> from Massageluxe of Pembroke Pines. I was calling becasue we were not able to collect the dues for your ' +
      'membership and you have <b><em>' + prepaids + '</em></b> hours on your account and we didn\'t want you to lose them.' +
      'Is It possible your card expired or changed?</p>' +
      '<p class="text-center"><b><em>[Pause for response]</b></em></p>' +
      '<p>You are more than 2 months behind but all we are asking to get your account current ' +
      'is 2 month of dues now and all the hours you have will become active once again. ' +
      'Could we update the payment you have on file now and run the dues for the 2 months?</p>' +
      '<p class="text-center"><b><em>[Collect New Card information AND COLLECT DUES]</em></b></p>' +
      'Ok we have your account updated.  Would you like to book your next appointment?';
  }

}
