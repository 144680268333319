import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NewMember } from 'src/app/models/new-member.model';

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.css']
})
export class ModalConfirmDeleteComponent implements OnInit {

  @Input() member: NewMember;
  @Output() deleteMember = new EventEmitter<NewMember>();
  @Output() returnToList = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  deleteYes() {
    this.deleteMember.emit(this.member);
  }

  deleteNo() {
    this.returnToList.emit('No');
  }

}
