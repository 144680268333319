import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { Guest } from '../models/guest.model';
import { Employee } from '../models/employee.model';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CallLog } from '../models/call-log.model';
import { Observable } from 'rxjs';
import { Rank } from '../models/rank.model';
import { EmployeeCallCountModel } from '../models/employee-call-count.model';

@Injectable({
  providedIn: 'root'
})
export class CallLogService extends BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService) {
    super(http, constants, constants.api_employees);
  }

  logCall(callResult: string, guest: Guest, emp: Employee): Observable<any> {
    const d = Date.now;
    const log = new CallLog('', callResult, guest.id, emp.id, this.getTimeCalled(), guest.customerTypeId);
    return this.http.post<any>(this.constants.api_call_log + environment.api_code, log)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getCurrentCount(): Observable<number> {
    return this.http.get<number>(this.constants.api_call_log_current_count + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getBookedCount(): Observable<number> {
    return this.http.get<number>(this.constants.api_call_log_by_status + '/' + this.constants.call_log_booked + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getRanking(): Observable<Rank[]> {
    return this.http.get<Rank[]>(this.constants.api_call_log_rank + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getMyBookedForPeriod(empId: string, periodId?: string): Observable<number> {
    if (periodId) {
      return this.http.get<number>(this.constants.api_call_log_period_employee_status + '/' + empId +
        '/' + this.constants.call_log_booked + '/' + periodId + environment.api_code)
        .pipe(catchError((e: any) => this.handleError(e)));
    }

    return this.http.get<number>(this.constants.api_call_log_period_employee_status + '/' + empId +
      '/' + this.constants.call_log_booked + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getMyCalledForPeriod(empId: string, periodId?: string): Observable<number> {
    if (periodId) {
      return this.http.get<number>(this.constants.api_call_log_period_employee_called + '/' + empId + '/' + periodId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
    }

    return this.http.get<number>(this.constants.api_call_log_period_employee_called + '/' + empId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getMyCalledGuestsListForPeriod(empId: string, periodId?: string): Observable<Guest[]> {
    if (periodId) {
      return this.http.get<Guest[]>(this.constants.api_call_log_period_employee_called_list + '/' + empId + '/' + periodId + environment.api_code)
        .pipe(catchError((e: any) => this.handleError(e)));
    }

    return this.http.get<Guest[]>(this.constants.api_call_log_period_employee_called_list + '/' + empId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getEmployeeSummary(periodId: string): Observable<EmployeeCallCountModel[]> {
    return this.http.get<EmployeeCallCountModel[]>(this.constants.api_call_log_employee_summary + '/' + periodId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getTimeCalled(): string {

    const hour = new Date().getHours();

    if (hour < 15) {
      return 'AM';
    }

    return 'PM';
  }
}
