import { Component, OnInit, Input } from '@angular/core';
import { ClosingNumber } from 'src/app/models/closing-number.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-goals-view',
  templateUrl: './goals-view.component.html',
  styleUrls: ['./goals-view.component.css']
})
export class GoalsViewComponent implements OnInit {

  @Input() employeeGoals: ClosingNumber[];
  @Input() emptyList: boolean;
  @Input() loading: boolean;
  @Input() showEdit: boolean;
  lastUpdated: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.getLatestDate();
    this.setGoalMet();
  }

  setGoalMet() {
    if (this.employeeGoals && this.employeeGoals.length > 1) {
      this.employeeGoals.forEach(i => {

        if (Number(i.closeRate) >= Number(i.goal)) {
          i.goalMet = true;
        } else {
          i.goalMet = false;
        }
      });
    }
  }

  getLatestDate() {
    if (this.employeeGoals && this.employeeGoals.length > 1) {
      this.employeeGoals.reduce((a: ClosingNumber, b: ClosingNumber) => {
        const first = new Date(a.created);
        const second = new Date(b.created);
        let max: ClosingNumber;

        if (first > second) {
          this.lastUpdated = a.created;
          max = a;
        } else {
          this.lastUpdated = b.created;
          max = b;
        }

        return max;
      });
    }
  }

  editItem(c: ClosingNumber) {
    this.router.navigate(['/mgmt/pitches/' + c.periodId + '/' + c.employeeId]);
  }


}
