import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StoreStatsService } from 'src/app/services/store-stats.service';
import { StoreStat } from 'src/app/models/store-stat.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Period } from 'src/app/models/period.model';
import { ConstantsService } from 'src/app/services/constants.service';
import { MessageModel } from 'src/app/models/message.model';

@Component({
  selector: 'app-mgmt-store-stats',
  templateUrl: './mgmt-store-stats.component.html',
  styleUrls: ['./mgmt-store-stats.component.css']
})
export class MgmtStoreStatsComponent implements OnInit {

  period: Period;
  statform: FormGroup;
  storeStat: StoreStat;

  constructor(private fb: FormBuilder, private ss: StoreStatsService,
    private router: Router, private actRoute: ActivatedRoute, private constants: ConstantsService) { }

  ngOnInit() {
    this.createForm();
    this.actRoute.data.subscribe((d: { storeStat: StoreStat, period: Period }) => {
      if (d) {
        this.storeStat = d.storeStat;
        this.period = d.period;
        this.bindForm();
      } else {
        this.constants.sendMessage.next(new MessageModel('Could not find a record for the stats of this period.', 'alert-danger'));
      }
    });
  }

  createForm() {
    this.statform = this.fb.group({
      totalMembers: ['', [Validators.required]],
      cancels: ['', [Validators.required]],
      freezes: ['', [Validators.required]],
      closeRate: ['', [Validators.required]],
      prospects: ['', [Validators.required]],
      newMembers: ['', [Validators.required]]
    });
  }

  bindForm() {
    this.statform.patchValue({
      totalMembers: this.storeStat.totalMembers,
      freezes: this.storeStat.freezes,
      closeRate: this.storeStat.closeRate,
      cancels: this.storeStat.freezes,
      prospects: this.storeStat.prospects,
      newMembers: this.storeStat.newMembers
    });
  }

  updateStats(s: StoreStat) {
    this.ss.update(s).subscribe(d => { this.router.navigate(['/mgmt/pitches' + '/' + s.periodId]); });
  }

}
