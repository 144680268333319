import { Component, OnInit } from '@angular/core';
import { AddOnSale } from 'src/app/models/addon-sale.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { AddonSalesService } from 'src/app/services/addon-sales.service';
import { ActivatedRoute } from '@angular/router';
import { MessageModel } from 'src/app/models/message.model';
import { CheckUrlValidator } from 'src/app/validators/check-url-validator';
import { Employee } from 'src/app/models/employee.model';

@Component({
  selector: 'app-addons-my-pending',
  templateUrl: './addons-my-pending.component.html',
  styleUrls: ['./addons-my-pending.component.css']
})
export class AddonsMyPendingComponent implements OnInit {

  addOnSales: AddOnSale[];
  emptyList: boolean;
  loading: boolean;
  loadingMessage: string;
  listTitle: string;
  showActions: boolean;
  showRemoved: boolean;
  showVerified: boolean;
  verifyForm: FormGroup;

  constructor(private fb: FormBuilder, private auth: AuthService, private constants: ConstantsService,
    private addOnService: AddonSalesService, private actRouter: ActivatedRoute) { }

  ngOnInit() {
    this.emptyList = true;
    this.loading = true;
    this.loadingMessage = 'Loading Addons...';
    this.listTitle = 'My Pending Add Ons';
    this.showActions = true;
    this.createVerifyForm();
    this.getAll();
  }

  getAll() {
    const e: Employee = this.auth.getPinnedUser();
    this.addOnService.getByEmployee(e.id).subscribe(d => {
      this.addOnSales = d;
      if (d && d.length > 0) {
        this.emptyList = false;
      } else {
        this.emptyList = true;
      }
      this.loading = false;
    },
      err => {
        this.constants.sendMessage.next(new MessageModel('Error Loading Add On Sales...', 'alert-danger'));
        this.loading = false;
      });
  }

  createVerifyForm() {
    this.verifyForm = this.fb.group({
      checkId: ['', [Validators.required, Validators.minLength(46), CheckUrlValidator()]]
    });
  }

  removeFromTable(itemId: string) {
    this.addOnSales.splice(this.addOnSales.findIndex(i => i.id === itemId), 1);
  }

  verifyItem(item: AddOnSale) {
    const e = this.auth.getPinnedUser();
    this.addOnService.verify(item, e.id).subscribe(
      a => {
        this.constants.sendMessage.next(new MessageModel('Add On Sale Verified!', 'alert-success'));
        this.removeFromTable(a.id);
      },
      () => { this.constants.sendMessage.next(new MessageModel('Could not Update the item!', 'alert-danger')); }
    );
  }

  removeItem(item: AddOnSale) {
    if (!item) {
      this.constants.sendMessage.next(new MessageModel('No Item Selected!', 'alert-danger'));
      return;
    }
    const e = this.auth.getPinnedUser();

    if (!e) {
      this.constants.sendMessage.next(new MessageModel('User not Pinned In!', 'alert-danger'));
      return;
    }

    item.verifierId = e.id;

    this.addOnService.removeItem(item).subscribe(
      () => {
        // this.addOnSales.splice(this.addOnSales.findIndex(i => i.id === item.id), 1);
        this.removeFromTable(item.id);
        this.constants.sendMessage.next(new MessageModel('Item Has been sent to the Removed List', 'alert-success'));
      },
      () => {
        this.constants.sendMessage.next(
          new MessageModel('There was an issue removing the item from the list.  Try Again later.', 'alert-danger'));
      }
    );
  }

}
