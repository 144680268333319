import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DfrItem } from 'src/app/models/dfr-item.model';

@Component({
  selector: 'app-dfr-form',
  templateUrl: './dfr-form.component.html',
  styleUrls: ['./dfr-form.component.css']
})
export class DfrFormComponent implements OnInit {

  @Input() dfrForm: FormGroup;
  @Input() dfr: DfrItem;
  isNew: boolean;

  constructor() { }

  ngOnInit() {
    this.isNew = true;
  }

}
