import { Component, OnInit } from '@angular/core';
import { QuoteService } from 'src/app/services/quote.service';
import { Quote } from 'src/app/models/quote.model';

@Component({
  selector: 'app-quote-of-day',
  templateUrl: './quote-of-day.component.html',
  styleUrls: ['./quote-of-day.component.css']
})
export class QuoteOfDayComponent implements OnInit {

  quoteOfDay: Quote;

  constructor(private quotes: QuoteService) { }

  ngOnInit() {
    this.quotes.get().subscribe(q => this.quoteOfDay = q.quote);
  }

}
