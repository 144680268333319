import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { Observable } from 'rxjs';
import { AddOnSale } from '../models/addon-sale.model';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EmployeeAddOnCountModel } from '../models/employee-addon-count.model';

@Injectable({
  providedIn: 'root'
})
export class AddonSalesService extends BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService) {
    super(http, constants, constants.api_addon_sales);
  }

  getSalesSummary(periodId: string): Observable<EmployeeAddOnCountModel[]> {
    return this.http.get<EmployeeAddOnCountModel[]>(this.constants.api_addon_sales_summary + '/' + periodId + environment.api_code)
      .pipe(catchError(this.handleError));
  }

  getEmployeeSummary(periodId: string, employeeId: string): Observable<EmployeeAddOnCountModel> {
    return this.http.get<EmployeeAddOnCountModel>(
      this.constants.api_addon_sales_summary + '/' + periodId + '/' + employeeId + environment.api_code)
    .pipe(catchError(this.handleError));
  }

  getByFilter(filter: string): Observable<AddOnSale[]> {
    if (!filter) {
      this.getAll();
    }

    return this.http.get<AddOnSale[]>(this.constants.api_addon_sales_filter + '/' + filter + environment.api_code)
      .pipe(catchError(this.handleError));
  }

  removeItem(item: AddOnSale): Observable<any> {
    return this.http.delete(this.constants.api_addon_sales + '/' + item.id + '/' + item.verifierId + environment.api_code)
      .pipe(catchError(this.handleError));
  }

  verify(item: AddOnSale, employeeId): Observable<any> {
    return this.http.post(this.constants.api_addon_sales_verify + '/' + employeeId + environment.api_code, item)
      .pipe(catchError(this.handleError));
  }

  getByPayPeriod(periodId: string, employeeId?: string): Observable<AddOnSale[]> {
    let uri = this.constants.api_addon_sales_pay_period + '/' + periodId;

    if (employeeId) {
      uri += '/' + employeeId;
    }

    return this.http.get<AddOnSale[]>(uri + environment.api_code)
      .pipe(catchError(this.handleError));
  }

  getByEmployee(employeeId?: string, periodId?: string): Observable<AddOnSale[]> {

    let uri = this.constants.api_addon_sales;
    if (employeeId) {
      uri += '/' + employeeId;
    }

    if (periodId) {
      uri += '/' + periodId;
    }

    return this.http.get<AddOnSale[]>(uri + environment.api_code)
      .pipe(catchError(this.handleError));
  }
}
