import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DfrItem } from 'src/app/models/dfr-item.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Period } from 'src/app/models/period.model';
import { PeriodService } from 'src/app/services/period.service';
import { DfrService } from 'src/app/services/dfr.service';

@Component({
  selector: 'app-dfr-view',
  templateUrl: './dfr-view.component.html',
  styleUrls: ['./dfr-view.component.css']
})
export class DfrViewComponent implements OnInit {

  emptyList: boolean;
  loading: boolean;
  loadingMessage = 'Loading DFRs for Period';
  period: Period;
  periods: Period[];

  editPath = 'mgmt/dfrs/edit';
  dfrs: DfrItem[];

  constructor(private fb: FormBuilder, private actRoute: ActivatedRoute,
    private router: Router, private ps: PeriodService, private dfrservice: DfrService) { }

  ngOnInit() {

    this.actRoute.data.subscribe((d: { periods: Period[], period: Period }) => {
      this.periods = d.periods;
      this.period = d.period;

      this.getSummary();

    });
  }

  editDfr(dfrId?: string) {
    if (dfrId) {
      console.log('DFR ID included path...');
      this.router.navigate([this.editPath + '/' + dfrId]);
    } else {
      console.log('DFR Id empty...' + this.editPath);
      this.router.navigate([this.editPath]);
    }
  }

  getSummary() {
    this.loading = true;
    this.dfrservice.getDfrsForPeriod(this.period.id).subscribe(d => {
      this.dfrs = d;
      this.loading = false;
      if (d.length < 1) {
        this.emptyList = true;
      } else {
        this.emptyList = false;
      }

    }, err => {
      console.log('ERROR: ' + JSON.stringify(err));
      this.loading = false;
    });
  }

  changePeriod(periodId: string) {
    this.loading = true;
    this.ps.getItem(periodId).subscribe(d => {
      this.period = d;
      this.getSummary();
    });
  }
}
