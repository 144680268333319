import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddOnSale } from 'src/app/models/addon-sale.model';
import { EmployeeAddOnCountModel } from 'src/app/models/employee-addon-count.model';
import { Employee } from 'src/app/models/employee.model';
import { Period } from 'src/app/models/period.model';
import { AddonSalesService } from 'src/app/services/addon-sales.service';
import { AuthService } from 'src/app/services/auth.service';
import { PeriodService } from 'src/app/services/period.service';

@Component({
  selector: 'app-addons-my-score',
  templateUrl: './addons-my-score.component.html',
  styleUrls: ['./addons-my-score.component.css']
})
export class AddonsMyScoreComponent implements OnInit {

  myAddons: EmployeeAddOnCountModel;
  period: Period;
  employee: Employee;

  constructor(private authService: AuthService,
      private addonService: AddonSalesService,
      private ps: PeriodService, private actRoute: ActivatedRoute) { }

  ngOnInit() {
    this.actRoute.data.subscribe((d: { period: Period }) => {
      this.period = d.period;
      this.getEmployeeAddOns();
    });
  }

  getEmployeeAddOns() {
    const e = this.authService.getPinnedUser();
    this.addonService.getEmployeeSummary(this.period.id, e.id).subscribe(ea => {
      this.myAddons = ea;
    });
  }

}
