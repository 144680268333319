import { BaseModel } from './base.model';
import { Employee } from './employee.model';
import { Guest } from './guest.model';
import { DataSegment } from './data-segment.model';

export class CallLog extends BaseModel {
  constructor(
    id?: string,
    callResultId?: string,
    guestId?: string,
    employeeId?: string,
    timeCalled?: string,
    customerTypeId?: string,
    employee?: Employee,
    guest?: Guest,
    customerType?: DataSegment,
    callResult?: DataSegment
  ) {
    super(id);
    this.callResultId = callResultId;
    this.guestId = guestId;
    this.employeeId = employeeId;
    this.timeCalled = timeCalled;
    this.customerTypeId = customerTypeId;
    this.employee = employee;
    this.guest = guest;
    this.customerType = customerType;
    this.callResult = callResult;
  }

  callResultId: string;
  guestId: string;
  employeeId: string;
  timeCalled: string;
  customerTypeId: string;
  employee: Employee;
  guest: Guest;
  customerType: DataSegment;
  callResult: DataSegment;
}
