import { Injectable } from '@angular/core';
import { PeriodService } from '../period.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Period } from 'src/app/models/period.model';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PeriodResolverService implements Resolve<Period> {

  constructor(private svc: PeriodService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Period> | Observable<never> {
    const id = route.paramMap.get('periodId');

    return this.svc.getItem(id).pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
