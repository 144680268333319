import { Component, OnInit, Input } from '@angular/core';
import { DfrItem } from 'src/app/models/dfr-item.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dfr-list',
  templateUrl: './dfr-list.component.html',
  styleUrls: ['./dfr-list.component.css']
})
export class DfrListComponent implements OnInit {

  @Input() dfrItems: DfrItem[];

  constructor(private actRoute: ActivatedRoute) { }

  ngOnInit() {

  }

}
