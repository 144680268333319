import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClosingNumber } from 'src/app/models/closing-number.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Period } from 'src/app/models/period.model';
import { Employee } from 'src/app/models/employee.model';

@Component({
  selector: 'app-mgmt-pitches-edit',
  templateUrl: './mgmt-pitches-edit.component.html',
  styleUrls: ['./mgmt-pitches-edit.component.css']
})
export class MgmtPitchesEditComponent implements OnInit {

  period: Period;
  employee: Employee;
  pitchForm: FormGroup;
  closingNumber: ClosingNumber;

  constructor(private fb: FormBuilder, private es: EmployeeService, private actRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.createForm();
    this.actRoute.data.subscribe((d: { closingNumber: ClosingNumber, employee: Employee, period: Period }) => {
      this.employee = d.employee;
      this.period = d.period;
      this.closingNumber = d.closingNumber;
      this.editClosingNumbers();
    });
  }

  createForm() {
    this.pitchForm = this.fb.group({
      pitchCount: [0, [Validators.required]],
      pitchSuccesses: [0, [Validators.required]],
      closeRate: [0, [Validators.required]],
      goal: [35, [Validators.required]]
    });
  }

  editClosingNumbers() {
    this.pitchForm.patchValue({
      pitchCount: this.closingNumber.pitchCount,
      pitchSuccesses: this.closingNumber.pitchSuccesses,
      goal: this.closingNumber.goal,
      closeRate: this.closingNumber.closeRate
    });
  }

  updatePitches(cn: ClosingNumber) {
    this.es.updateClosingNumbers(cn).subscribe(d => {
      this.router.navigate(['/mgmt/pitches' + '/' + cn.periodId]);
    });
  }

}
