import { Component, OnInit } from '@angular/core';
import { Period } from 'src/app/models/period.model';
import { ActivatedRoute } from '@angular/router';
import { AddonSalesService } from 'src/app/services/addon-sales.service';
import { AuthService } from 'src/app/services/auth.service';
import { AddOnSale } from 'src/app/models/addon-sale.model';
import { FormGroup } from '@angular/forms';
import { DataSegment } from 'src/app/models/data-segment.model';
import { PeriodService } from 'src/app/services/period.service';
import { Employee } from 'src/app/models/employee.model';

@Component({
  selector: 'app-addons-my',
  templateUrl: './addons-my.component.html',
  styleUrls: ['./addons-my.component.css']
})
export class AddonsMyComponent implements OnInit {

  periods: Period[];
  period: Period;
  loading: boolean;
  loadingMessage: string;
  emptyList: boolean;
  filter: string;
  items: AddOnSale[];
  listTitle: string;
  showVerified: boolean;

  constructor(private actRoute: ActivatedRoute, private addOnService: AddonSalesService,
    private ps: PeriodService, private auth: AuthService) { }

  ngOnInit() {
    this.showVerified = true;
    this.emptyList = true;
    const e: Employee = this.auth.getPinnedUser();
    this.listTitle = e.displayName + '\'s Verified';
    this.actRoute.data.subscribe((d: { periods: Period[], period: Period }) => {
      this.periods = d.periods;
      this.period = d.period;
      this.getSummary();
    });
  }

  getSummary() {
    this.loading = true;
    const e = this.auth.getPinnedUser();
    if (this.period) {
      this.addOnService.getByPayPeriod(this.period.id, e.id).subscribe(d => {
        this.items = d;
        if (d && d.length > 0) {
          this.emptyList = false;
        } else {
          this.emptyList = true;
        }

       // console.log(JSON.stringify('data: ' + this.items));
        this.loading = false;
      });
    }
    this.loading = false;
  }

  changePeriod(periodId: string) {
    this.ps.getItem(periodId).subscribe(d => {
      this.period = d;
      this.getSummary();
    });
  }

}
