import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ClosingNumber } from 'src/app/models/closing-number.model';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { EmployeeService } from '../employee.service';

@Injectable({
  providedIn: 'root'
})
export class ClosingNumbersEmployeePeriodService implements Resolve<ClosingNumber> {

  constructor(private emps: EmployeeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClosingNumber> | Observable<never> {

    const empId = route.paramMap.get('id');
    const periodId = route.paramMap.get('periodId');

    return this.emps.getClosingNumberEmployeePeriod(empId, periodId).pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
