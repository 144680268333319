import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Period } from 'src/app/models/period.model';
import { Employee } from 'src/app/models/employee.model';
import { ClosingNumber } from 'src/app/models/closing-number.model';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-goals-form',
  templateUrl: './goals-form.component.html',
  styleUrls: ['./goals-form.component.css']
})
export class GoalsFormComponent implements OnInit {

  @Input() period: Period;
  @Input() employee: Employee;
  @Input() closingNumber: ClosingNumber;
  @Input() pitchForm: FormGroup;
  @Output() updateNumbers = new EventEmitter();

  submitted: boolean;
  loadingMsg: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.submitted = false;
    this.loadingMsg= 'saving item...';
  }

  submitNumbers() {
    this.submitted = true;
    this.closingNumber.closeRate = this.pitchForm.value.closeRate;
    this.closingNumber.goal = this.pitchForm.value.goal;
    this.closingNumber.pitchSuccesses = this.pitchForm.value.pitchSuccesses;
    this.closingNumber.pitchCount = this.pitchForm.value.pitchCount;

    this.updateNumbers.emit(this.closingNumber);
  }

  goBack() {
    this.router.navigate(['/mgmt/pitches' + '/' + this.period.id]);
  }
}
