import { Component, OnInit } from '@angular/core';
import { Period } from 'src/app/models/period.model';
import { EmployeeAddOnCountModel } from 'src/app/models/employee-addon-count.model';
import { AddonSalesService } from 'src/app/services/addon-sales.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { PeriodService } from 'src/app/services/period.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-mgmt-addons',
  templateUrl: './mgmt-addons.component.html',
  styleUrls: ['./mgmt-addons.component.css']
})
export class MgmtAddonsComponent implements OnInit {

  periods: Period[];
  monthPeriods: Period[];
  currentPeriod: Period;
  payPeriods: Period[];
  period: Period;
  loading: boolean;
  emptyList: boolean;
  employees: EmployeeAddOnCountModel[];
  loadingMessage = 'Loading Summaries...';
  filter = 'Month';
  isMonth = true;

  constructor(private addOns: AddonSalesService,
    private ps: PeriodService, private actRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.actRoute.paramMap.subscribe(params => {
      const p = params.get('filter');

      this.validateFilter(p);

      this.actRoute.data.subscribe((d: { monthPeriods: Period[], payPeriods: Period[] }) => {
        this.monthPeriods = d.monthPeriods;
        this.payPeriods = d.payPeriods;
        console.log(this.payPeriods.length);
        //Get current Period based on Type
        this.ps.getCurrent(this.filter).subscribe(d => {
          this.period = d;
          this.setPeriods();
          this.getSummary();
        });

      });
    });
  }

  getCurrentPeriod() {

  }

  validateFilter(param: string) {
    param = this.capitalize(param);
    if (param && (param === 'Month' || param === 'Pay')) {
      this.filter = param;
    } else {
      this.filter = 'Month';
    }
  }

  getSummary() {
    this.loading = true;
    this.addOns.getSalesSummary(this.period.id).subscribe(d => {
      this.employees = d;
      this.loading = false;
    }, err => {
      console.log('ERROR: ' + JSON.stringify(err));
      this.loading = false;
    });
  }

  getDetails(empId: string) {
    this.loading = true;
    this.loadingMessage = 'Loading Details...';
    this.router.navigate(['/mgmt/addons/' + this.filter + '/' + this.period.id + '/' + empId]);
  }

  capitalize(item: string) { 
    if (!item) { return item; }
    return item.charAt(0).toUpperCase() + item.slice(1);
  }

  setPeriods() {
    if (this.filter === 'Pay') {
      this.isMonth = false;
      this.periods = this.payPeriods;

    } else {
      this.isMonth = true;
      this.periods = this.monthPeriods;
    }
  }

  changePeriodType(type: string) {
    this.filter = this.capitalize(type);
    this.setPeriods();
    this.setDefaultPeriod();
  }

  setDefaultPeriod()
  {
    //Get current Period based on Type
        this.ps.getCurrent(this.filter).subscribe(d => {
          this.period = d;
          this.setPeriods();
          this.getSummary();
        });
  }

  changePeriod(periodId: string) {
    this.loading = true;
    this.ps.getItem(periodId).subscribe(d => {
      this.period = d;
      this.setPeriods();
      this.getSummary();
    });
  }
}
