import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { Observable, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(public http: HttpClient, public constants: ConstantsService) { }

  handleError(error: Response) {
    console.error(error);
    return EMPTY;
  }

  get(): Observable<any> {
    return this.http.get(this.constants.api_quotes)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
}
