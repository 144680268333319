import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Employee } from 'src/app/models/employee.model';
import { ConstantsService } from 'src/app/services/constants.service';
import { MessageModel } from 'src/app/models/message.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'app';
  loginForm: FormGroup;
  pinUser: Employee;

  constructor(public auth: AuthService, private constants: ConstantsService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.createPinForm();
    this.pinUser = this.auth.getPinnedUser();
  }

  createPinForm() {
    this.loginForm = this.fb.group({
      pin: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(3)]]
    });
  }

  pinIn() {
    this.auth.pinIn(this.loginForm.value.pin).subscribe(p => {
      if (p) {
        sessionStorage.setItem(this.constants.key_pin_user, JSON.stringify(p));
        this.pinUser = p;
        this.loginForm.reset();
        this.constants.sendMessage.next(null);
      } else {

        this.constants.sendMessage.next(new MessageModel('Invalid Pin', 'alert-danger'));
      }
    });
  }

  pinOut() {
    this.auth.pinOut();
  }

  checkReturn(e) {
    if (e && e.keyCode === 13) {
      this.pinIn();
    }
  }

  isPinnedIn(): boolean {
    return this.auth.isPinned();
  }

  getPinUser() {
    this.pinUser = this.auth.getPinnedUser();
  }
}
