import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WaitList } from 'src/app/models/waitlist-model';

@Component({
  selector: 'app-waitlist-list',
  templateUrl: './waitlist-list.component.html',
  styleUrls: ['./waitlist-list.component.css']
})
export class WaitlistListComponent implements OnInit {

  @Input() waitListItems: WaitList[];
  @Input() emptyList: boolean;
  @Input() loading: boolean;
  @Input() loadingMessage: string;
  @Output() remove = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  removeFromWaitList(itemId: string) {
    this.remove.emit(itemId);
  }

}
