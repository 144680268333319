import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './components/app/app.component';
import { AppRouterModule } from './modules/app-router/app-router.module';
import {
  MatTableModule, MatButtonModule, MatPaginatorModule, MatSortModule,
  MatMenuModule, MatSidenavModule, MatToolbarModule, MatCardModule
} from '@angular/material';

import { YesNoPipe } from './pipes/yes-no.pipe';

import { ConstantsService } from './services/constants.service';
import { BaseService } from './services/base.service';
import { EmployeeService } from './services/employee.service';
import { GuestService } from './services/guest.service';

import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
import { CallListViewComponent } from './components/calls/call-list-view/call-list-view.component';
import { CallsBookedListViewComponent } from './components/calls/calls-booked-list-view/calls-booked-list-view.component';
import { CallsMyComponent } from './components/calls/calls-my/calls-my.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { QuoteOfDayComponent } from './components/quote-of-day/quote-of-day.component';
import { GoalsViewComponent } from './components/goals/goals-view/goals-view.component';
import { MonthlyContestViewComponent } from './components/monthly-contest-view/monthly-contest-view.component';
import { PhonePipe } from './pipes/phone.pipe';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { JwtHelper } from './services/jwt-helper.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { MgmtComponent } from './components/mgmt/mgmt-view/mgmt.component';
import { PinGuard } from './guards/pin.guard';
import { CallsCompleteComponent } from './components/calls/calls-complete/calls-complete.component';
import { CallsCountsComponent } from './components/calls/calls-counts/calls-counts.component';
import { DataSegmentService } from './services/data-segment.service';
import { AddonsFormComponent } from './components/addons/addons-form/addons-form.component';
import { AddonsListComponent } from './components/addons/addons-list/addons-list.component';
import { AddonsViewComponent } from './components/addons/addons-view/addons-view.component';
import { WaitlistViewComponent } from './components/waitlist/waitlist-view/waitlist-view.component';
import { WaitlistFormComponent } from './components/waitlist/waitlist-form/waitlist-form.component';
import { WaitlistListComponent } from './components/waitlist/waitlist-list/waitlist-list.component';
import { CallsScoreboardComponent } from './components/calls/calls-scoreboard/calls-scoreboard.component';
import { PeriodService } from './services/period.service';
import { CallsCompleteListComponent } from './components/calls/calls-complete-list/calls-complete-list.component';
import { NavTopComponent } from './components/nav/nav-top/nav-top.component';
import { ModalCallScriptComponent } from './components/modal-call-script/modal-call-script.component';
import { ModalCallCompleteComponent } from './components/modal-call-complete/modal-call-complete.component';
import { NavCallsComponent } from './components/nav/nav-calls/nav-calls.component';
import { MgmtNavComponent } from './components/nav/mgmt-nav/mgmt-nav.component';
import { ViewBaseComponent } from './components/view-base/view-base.component';
import { MgmtPitchesComponent } from './components/mgmt/mgmt-pitches/mgmt-pitches.component';
import { MgmtCallLogComponent } from './components/mgmt/mgmt-call-log/mgmt-call-log.component';
import { MgmtEmployeesComponent } from './components/mgmt/mgmt-employees/mgmt-employees.component';
import { MgmtCallLogDetailsComponent } from './components/mgmt/mgmt-call-log-details/mgmt-call-log-details.component';
import { PeriodsSelectorComponent } from './components/periods-selector/periods-selector.component';
import { EmployeeListComponent } from './components/employee/employee-list/employee-list.component';
import { EmployeeFormComponent } from './components/employee/employee-form/employee-form.component';
import { GoalsFormComponent } from './components/goals/goals-form/goals-form.component';
import { MgmtEmployeeEditComponent } from './components/mgmt/mgmt-employee-edit/mgmt-employee-edit.component';
import { MgmtPitchesEditComponent } from './components/mgmt/mgmt-pitches-edit/mgmt-pitches-edit.component';
import { EmployeeResolverService } from './services/resolvers/employee-resolver.service';
import { ClosingNumbersResolverService } from './services/resolvers/closing-numbers-resolver.service';
import { PeriodsResolverService } from './services/resolvers/periods-resolver.service';
import { CallLogsResolverService } from './services/resolvers/call-logs-resolver.service';
import { CallLogsListResolverService } from './services/resolvers/call-logs-list-resolver.service';
import { GuestsResolverService } from './services/resolvers/guests-resolver.service';
import { PeriodsCurrentResolverService } from './services/resolvers/periods-current-resolver.service';
import { EmployeeListResolverService } from './services/resolvers/employee-list-resolver.service';
import { GuestListResolverService } from './services/resolvers/guest-list-resolver.service';
import { LoadingViewComponent } from './components/loading-view/loading-view.component';
import { ClosingNumbersEmployeePeriodService } from './services/resolvers/closing-numbers-employee-period.service';
import { GoalsStatsViewComponent } from './components/goals/goals-stats-view/goals-stats-view.component';
import { GoalsStatsFormComponent } from './components/goals/goals-stats-form/goals-stats-form.component';
import { MgmtStoreStatsComponent } from './components/mgmt/mgmt-store-stats/mgmt-store-stats.component';
import { StoreStatsService } from './services/store-stats.service';
import { UpdateHistoryService } from './services/update-history.service';
import { WorkingModalViewComponent } from './components/working-modal-view/working-modal-view.component';
import { AddonSalesService } from './services/addon-sales.service';
import { AddonItemsResolverService } from './services/resolvers/addon-items-resolver.service';
import { NavAddonsComponent } from './components/nav/nav-addons/nav-addons.component';
import { AddonSalesResolverService } from './services/resolvers/addon-sales-resolver.service';
import { AddonsVerifyModalComponent } from './components/addons/addons-verify-modal/addons-verify-modal.component';
import { CustomerTypePipe } from './pipes/customer-type.pipe';
import { PeriodsPayResolverService } from './services/resolvers/periods-pay-resolver.service';
import { AddonsMyComponent } from './components/addons/addons-my/addons-my.component';
import { MgmtAddonsComponent } from './components/mgmt/mgmt-addons/mgmt-addons.component';
import { PeriodPayResolverService } from './services/resolvers/period-pay-resolver.service';
import { AddonsMyPendingComponent } from './components/addons/addons-my-pending/addons-my-pending.component';
import { AddonsListNoformComponent } from './components/addons/addons-list-noform/addons-list-noform.component';
import { MgmtAddonsDetailsComponent } from './components/mgmt/mgmt-addons-details/mgmt-addons-details.component';
import { AddonsBoardComponent } from './components/addons/addons-board/addons-board.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { DfrViewComponent } from './components/mgmt/dfr/dfr-view/dfr-view.component';
import { DfrFormComponent } from './components/mgmt/dfr/dfr-form/dfr-form.component';
import { DfrListComponent } from './components/mgmt/dfr/dfr-list/dfr-list.component';
import { DfrEditComponent } from './components/mgmt/dfr/dfr-edit/dfr-edit.component';
import { DfrItemResolverService } from './services/resolvers/dfr-item-resolver.service';
import { DfrListResolverService } from './services/resolvers/dfr-list-resolver.service';
import { DfrService } from './services/dfr.service';
import { EmployeePinFormComponent } from './components/employee/employee-pin-form/employee-pin-form.component';
import { MgmtEmployeePinEditComponent } from './components/mgmt/mgmt-employee-pin-edit/mgmt-employee-pin-edit.component';
import { WaitlistTodayComponent } from './components/waitlist/waitlist-today/waitlist-today.component';
import { AddonsMyScoreComponent } from './components/addons/addons-my-score/addons-my-score.component';
import { AddonsScoreboardComponent } from './components/addons/addons-scoreboard/addons-scoreboard.component';
import { NewMemberFormComponent } from './components/new-members/new-member-form/new-member-form.component';
import { NewMemberListComponent } from './components/new-members/new-member-list/new-member-list.component';
import { NewMembersViewComponent } from './components/new-members/new-members-view/new-members-view.component';
import { NewMemberEditComponent } from './components/new-members/new-member-edit/new-member-edit.component';
import { DatePipe } from '@angular/common';
import { ModalConfirmDeleteComponent } from './components/modal-confirm-delete/modal-confirm-delete.component';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    YesNoPipe,
    CustomerTypePipe,
    LoginComponent,
    CallListViewComponent,
    CallsBookedListViewComponent,
    CallsMyComponent,
    SafeHtmlPipe,
    QuoteOfDayComponent,
    GoalsViewComponent,
    MonthlyContestViewComponent,
    PhonePipe,
    MgmtComponent,
    CallsCompleteComponent,
    CallsCountsComponent,
    AddonsFormComponent,
    AddonsListComponent,
    AddonsViewComponent,
    WaitlistViewComponent,
    WaitlistFormComponent,
    WaitlistListComponent,
    CallsScoreboardComponent,
    CallsCompleteListComponent,
    NavTopComponent,
    ModalCallScriptComponent,
    ModalCallCompleteComponent,
    NavCallsComponent,
    MgmtNavComponent,
    ViewBaseComponent,
    MgmtPitchesComponent,
    MgmtCallLogComponent,
    MgmtEmployeesComponent,
    MgmtCallLogDetailsComponent,
    PeriodsSelectorComponent,
    EmployeeListComponent,
    EmployeeFormComponent,
    GoalsFormComponent,
    MgmtEmployeeEditComponent,
    MgmtPitchesEditComponent,
    LoadingViewComponent,
    GoalsStatsViewComponent,
    GoalsStatsFormComponent,
    MgmtStoreStatsComponent,
    WorkingModalViewComponent,
    NavAddonsComponent,
    AddonsVerifyModalComponent,
    AddonsMyComponent,
    MgmtAddonsComponent,
    AddonsMyPendingComponent,
    AddonsListNoformComponent,
    MgmtAddonsDetailsComponent,
    AddonsBoardComponent,
    LocalDatePipe,
    DfrViewComponent,
    DfrFormComponent,
    DfrListComponent,
    DfrEditComponent,
    EmployeePinFormComponent,
    MgmtEmployeePinEditComponent,
    WaitlistTodayComponent,
    AddonsMyScoreComponent,
    AddonsScoreboardComponent,
    NewMemberFormComponent,
    NewMemberListComponent,
    NewMembersViewComponent,
    NewMemberEditComponent,
    ModalConfirmDeleteComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    AppRouterModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule
  ],
  exports: [
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule
  ],
  providers: [
    ConstantsService,
    BaseService,
    EmployeeService,
    GuestService,
    DataSegmentService,
    PeriodService,
    StoreStatsService,
    UpdateHistoryService,
    EmployeeResolverService,
    AddonSalesService,
    DfrService,
    EmployeeListResolverService,
    ClosingNumbersResolverService,
    ClosingNumbersEmployeePeriodService,
    PeriodsResolverService,
    CallLogsResolverService,
    CallLogsListResolverService,
    GuestsResolverService,
    GuestListResolverService,
    PeriodsCurrentResolverService,
    AddonItemsResolverService,
    AddonSalesResolverService,
    PeriodsPayResolverService,
    PeriodPayResolverService,
    DfrItemResolverService,
    DfrListResolverService,
    PinGuard,
    AuthGuard,
    AuthService,
    DatePipe,
    JwtHelper,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
