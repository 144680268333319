import { Component, OnInit } from '@angular/core';
import { Guest } from 'src/app/models/guest.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { CallLogService } from 'src/app/services/call-log.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-calls-my',
  templateUrl: './calls-my.component.html',
  styleUrls: ['./calls-my.component.css']
})

export class CallsMyComponent implements OnInit {

  constructor(private employeeService: EmployeeService, private constants: ConstantsService,
    private callLog: CallLogService, public auth: AuthService, private route: ActivatedRoute) { }

  guests: Guest[];
  oneYrFilter = this.constants.guest_partitions_1yr;
  declinedEftFilter = this.constants.guest_partitions_declinedEft;
  calledCount: number;
  bookedCount: number;
  callsTitle = 'My Monthly Calls';
  listTitle: string;
  emptyList = true;
  loading = true;
  customerType: string;
  empId: string;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.customerType = params.get('customerTypeId');

      if (!this.customerType) {
        this.customerType = this.constants.guest_partitions_1yr;
      }

      const e = this.auth.getPinnedUser();
      this.empId = e.id;
      this.updateTitle();
      this.getCallList();
      this.callLog.getMyBookedForPeriod(this.empId).subscribe(data => this.bookedCount = data);
      this.callLog.getMyCalledForPeriod(this.empId).subscribe(data => this.calledCount = data);
    });
  }

  updateTitle() {
    if (this.customerType === this.constants.guest_partitions_1yr) {
      this.listTitle = '1Yr Guests';
    } else if (this.customerType === this.constants.guest_partitions_declinedEft) {
      this.listTitle = 'Declined EFTs';
    } else if (this.customerType === this.constants.guest_partitions_36mo) {
      this.listTitle = '3-11 Mo Guests';
    } else if (this.customerType === this.constants.guest_partitions_delinquent) {
      this.listTitle = 'Delinquent Members';
    } else {
      this.listTitle = 'Guests';
    }
  }

  assignCalls(event: any): void {
    this.loading = true;
    this.employeeService.createCallList(this.empId).subscribe(() => {
      this.getCallList();
    });
  }

  clearCallList(): void {
    this.loading = true;
    this.employeeService.clearAssignedCalls(this.empId).subscribe(() => {
      this.guests = [];
      this.emptyList = true;
      this.employeeService.changes.next(true);
      this.loading = false;
    });
  }

  getCallList(): void {
    this.loading = true;
    this.emptyList = true;
    this.guests = [];
    this.employeeService.getMyCallsByType(this.empId, this.customerType).subscribe(data => {
      this.guests = data;
      if (this.guests.length) {
        this.emptyList = false;
      }
      this.loading = false;
      this.employeeService.changes.next(true);
    });
  }

  updateItem(g: Guest) {
    this.guests.splice(this.guests.findIndex(i => i.id === g.id), 1);
    this.callLog.getMyBookedForPeriod(this.empId).subscribe(data => this.bookedCount = data);
    this.callLog.getMyCalledForPeriod(this.empId).subscribe(data => this.calledCount = data);
    // this.getCallList();
  }
}
