import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClosingNumber } from '../models/closing-number.model';
import { environment } from 'src/environments/environment.prod';
import { Employee } from '../models/employee.model';
import { Guest } from '../models/guest.model';
import { Rank } from '../models/rank.model';
import { PinQuery } from '../models/pin-query.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService) {
    super(http, constants, constants.api_employees);
  }

  getClosingNumbers(periodId?: string): Observable<ClosingNumber[]> {
    if (!periodId) {
      return this.http.get<ClosingNumber[]>(this.constants.api_employee_closingNumbers + environment.api_code)
        .pipe(catchError((e: any) => this.handleError(e)));
    }

    return this.http.get<ClosingNumber[]>(this.constants.api_employee_closingNumbers + '/' + periodId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));

  }

  getClosingNumberEmployeePeriod(employeeId: string, periodId: string): Observable<ClosingNumber> {
    return this.http.get<ClosingNumber>(this.constants.api_employee_closingNumbers
      + '/' + employeeId + '/' + periodId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  updateClosingNumbers(cn: ClosingNumber): Observable<any> {
    return this.http.put(this.constants.api_employee_closingNumbers
      + '/' + cn.employeeId + '/' + cn.periodId + environment.api_code, cn)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getScores(): Observable<Rank[]> {
    return this.http.get<Rank[]>(this.constants.api_employee_scores + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  pinIn(pin: string): Observable<Employee> {
    return this.http.post<Employee>(this.constants.api_employee_byPin + environment.api_code, { pin: pin })
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  pinRemove(employeeId: string): Observable<any> {
    return this.http.delete(this.constants.api_employee_byPin + '/' + employeeId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  pinUpsert(employeeId: string, pq: PinQuery): Observable<any> {
    return this.http.post<PinQuery>(this.constants.api_employee_byPin + '/' + employeeId + environment.api_code, pq)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getMyCalls(empId: string): Observable<Guest[]> {
    return this.http.get<Guest[]>(this.constants.api_employee_calls + '/' + empId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getMyCallsByType(empId: string, customerTypeId: string): Observable<Guest[]> {
    return this.http.get<Guest[]>(this.constants.api_employee_calls + '/' + empId + '/' + customerTypeId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  createCallList(empId: string): Observable<any> {
    return this.http.get<any>(this.constants.api_employee_create_call_list + '/' + empId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  createCallListForAll(empId: string): Observable<any> {
    return this.http.get<any>(this.constants.api_employee_create_call_list + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getCallListCount(empId: string): Observable<number> {
    return this.http.get<any>(this.constants.api_employee_call_list_count + '/' + empId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getCallListCountByType(empId: string, customerTypeId: string): Observable<number> {
    return this.http.get<any>(this.constants.api_employee_call_list_count + '/' + empId + '/' + customerTypeId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  clearAssignedCalls(empId: string): Observable<any> {
    return this.http.get<any>(this.constants.api_employee_clear_call_list + '/' + empId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getAllRecords(): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.constants.api_employees + 'All' + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
}
