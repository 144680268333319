import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { CallLogService } from 'src/app/services/call-log.service';

@Component({
  selector: 'app-nav-calls',
  templateUrl: './nav-calls.component.html',
  styleUrls: ['./nav-calls.component.css']
})
export class NavCallsComponent implements OnInit, OnDestroy {

  totalAssigned: number;
  total1Yr: number;
  totalDeclined: number;
  totalDelinquent: number;
  totalThreeMo: number;
  callsBaseUrl = '/calls/';
  oneYrUrl = this.callsBaseUrl + this.constants.guest_partitions_1yr;
  deftUrl = this.callsBaseUrl + this.constants.guest_partitions_declinedEft;
  delinquentUrl = this.callsBaseUrl + this.constants.guest_partitions_delinquent;
  threeMoUrl = this.callsBaseUrl + this.constants.guest_partitions_36mo;

  constructor(public auth: AuthService, private constants: ConstantsService,
    private employees: EmployeeService, private logService: CallLogService) { }

  ngOnInit() {
    this.getAssignCounts();
    this.logService.changes.subscribe(() => {
      this.getAssignCounts();
    });
    this.employees.changes.subscribe(() => {
      this.getAssignCounts();
    });
  }

  ngOnDestroy() {
    // this.employees.changes.unsubscribe();
  }

  getAssignCounts() {
    if (this.auth.isPinned()) {
      const e = this.auth.getPinnedUser();
      this.employees.getCallListCount(e.id).subscribe(c => this.totalAssigned = c);
      this.employees.getCallListCountByType(e.id, this.constants.guest_partitions_1yr).subscribe(c => this.total1Yr = c);
      this.employees.getCallListCountByType(e.id, this.constants.guest_partitions_declinedEft).subscribe(c => this.totalDeclined = c);
      this.employees.getCallListCountByType(e.id, this.constants.guest_partitions_delinquent).subscribe(c => this.totalDelinquent = c);
      this.employees.getCallListCountByType(e.id, this.constants.guest_partitions_36mo).subscribe(c => this.totalThreeMo = c);
    }
  }

}
