import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';
import { MessageModel } from 'src/app/models/message.model';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-view-base',
  templateUrl: './view-base.component.html',
  styleUrls: ['./view-base.component.css']
})
export class ViewBaseComponent implements OnInit {

  showError: boolean;
  errorMessage: MessageModel;
  messages: MessageModel[];

  constructor(private constants: ConstantsService, private router: Router) { }

  ngOnInit() {
    this.showError = false;
    this.messages = [];
    // Clear message from previous views.
    this.router.events
      .subscribe((e: NavigationStart) => {
        this.constants.sendMessage.next(null);
      });

    this.constants.sendMessage.subscribe((err) => {
      if (err && err.message) {

        if (!err.style) {
          err.style = 'alert-warning';
        }

        this.showError = true;
        this.errorMessage = err;
      } else {
        this.clearMessage();
      }
    });
  }

  clearMessage() {
    this.showError = false;
    this.errorMessage = null;
    this.messages = [];
  }
}
