import { Component, OnInit } from '@angular/core';
import { AddOnSale } from 'src/app/models/addon-sale.model';
import { AddonSalesService } from 'src/app/services/addon-sales.service';
import { PeriodService } from 'src/app/services/period.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Period } from 'src/app/models/period.model';
import { Employee } from 'src/app/models/employee.model';

@Component({
  selector: 'app-mgmt-addons-details',
  templateUrl: './mgmt-addons-details.component.html',
  styleUrls: ['./mgmt-addons-details.component.css']
})
export class MgmtAddonsDetailsComponent implements OnInit {

  addOnSales: AddOnSale[];
  title: string;
  selectedAddOn: AddOnSale;
  showRemoved: boolean = true;
  showVerified: boolean = true;
  showActions: boolean = false;
  periods: Period[];
  monthPeriods: Period[];
  currentPeriod: Period;
  payPeriods: Period[];
  period: Period;
  employee: Employee;
  loading: boolean;
  emptyList: boolean;
  loadingMessage = 'Loading Summaries...';
  filter = 'Month';
  isMonth = true;

  constructor(private addOns: AddonSalesService, private ps: PeriodService,
    private actRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.actRoute.paramMap.subscribe(params => {
      const p = params.get('filter');

      this.validateFilter(p);

      this.actRoute.data.subscribe((d: { monthPeriods: Period[], payPeriods: Period[], employee: Employee, period: Period }) => {
        this.monthPeriods = d.monthPeriods;
        this.payPeriods = d.payPeriods;
        this.employee = d.employee;
        this.period = d.period;
        this.setPeriods();
        this.getSummary();

        //console.log(this.payPeriods.length);

        //Get current Period based on Type
        // this.ps.getCurrent(this.filter).subscribe(d => {
        //   this.period = d;
        //   this.setPeriods();
        //   this.getSummary();
        // });

      });
    });
  }

  getSummary() {
    this.loading = true;
    this.addOns.getByPayPeriod(this.period.id, this.employee.id).subscribe(d => {
      this.addOnSales = d;
      this.loading = false;
    }, err => {
      console.log('ERROR: ' + JSON.stringify(err));
      this.loading = false;
    });
  }

  validateFilter(param: string) {
    param = this.capitalize(param);
    if (param && (param === 'Month' || param === 'Pay')) {
      this.filter = param;
    } else {
      this.filter = 'Month';
    }
  }

  capitalize(item: string) {
    if (!item) { return item; }
    return item.charAt(0).toUpperCase() + item.slice(1);
  }

  setPeriods() {
    if (this.filter === 'Pay') {
      this.isMonth = false;
      this.periods = this.payPeriods;

    } else {
      this.isMonth = true;
      this.periods = this.monthPeriods;
    }
  }

  changePeriodType(type: string) {
    this.filter = this.capitalize(type);
    this.setPeriods();
    this.setDefaultPeriod();
  }

  changePeriod(periodId: string) {
    this.loading = true;
    this.ps.getItem(periodId).subscribe(d => {
      this.period = d;
      this.setPeriods();
      this.getSummary();
    });
  }

  viewCheck(checkUri: string) {
    window.open(checkUri);
  }

  setDefaultPeriod()
  {
    //Get current Period based on Type
        this.ps.getCurrent(this.filter).subscribe(d => {
          this.period = d;
          this.setPeriods();
          this.getSummary();
        });
  }

  goBack() {

  }

}
