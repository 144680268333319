import { BaseModel } from './base.model';

export class PinQuery extends BaseModel {
  constructor(
    id?: string,
    requestorId?: string,
    pin?: string,
  ) {
    super(id);
    this.requestorId = requestorId;
    this.pin = pin;
  }

  pin: string;
  requestorId: string;
}
