import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
import { DataSegment } from 'src/app/models/data-segment.model';
import { DataSegmentService } from '../data-segment.service';

@Injectable({
  providedIn: 'root'
})
export class AddonItemsResolverService implements Resolve<DataSegment[]> {

  constructor(private svc: DataSegmentService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DataSegment[]> | Observable<never> {

    return this.svc.getAddOns().pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
