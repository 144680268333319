import { Employee } from "./employee.model";
import { DfrNote } from "./dfr-note.model";

export class DfrItem {
  constructor(id?: string, created?: string, employeeId?: string, reportDate?: string, cash?: number, check?: number, deposit?: number,
    totalsMatch?: boolean, uniformDeposit?: number, recieptsMatch?: boolean, returnTotal?: number, spaFinderMatch?: boolean,
    compsVerified?: boolean, luxeBuxe?: number, tipsMember?: number, tipsGuest?: number, tipsEmployee?: number, couponsVerified?: boolean,
    grouponsRedeemed?: boolean, billingVerified?: boolean, drawCountOpen?: boolean, drawCountMid?: boolean, drawCountClose?: boolean,
    incommingTotal?: number, outgoingTotal?: number, newMembers?: number, addOns?: number, productsSold?: number, submitter?: Employee,
    discrepancies?: DfrNote[]) {

    this.id = id;
    this.created = created;
    this.employeeId = employeeId;
    this.reportDate = reportDate;
    this.cash = cash;
    this.check = check;
    this.deposit = deposit;
    this.totalsMatch = totalsMatch;
    this.uniformDeposit = uniformDeposit;
    this.recieptsMatch = recieptsMatch;
    this.returnTotal = returnTotal;
    this.spaFinderMatch = spaFinderMatch;
    this.compsVerified = compsVerified;
    this.luxeBuxe = luxeBuxe;
    this.tipsMember = tipsMember;
    this.tipsEmployee = tipsEmployee;
    this.tipsGuest = tipsGuest;
    this.couponsVerified = couponsVerified;
    this.grouponsRedeemed = grouponsRedeemed;
    this.billingVerified = billingVerified;
    this.drawCountOpen = drawCountOpen;
    this.drawCountMid = drawCountMid;
    this.drawCountClose = drawCountClose;
    this.incomingTotal = incommingTotal;
    this.outgoingTotal = outgoingTotal;
    this.newMembers = newMembers;
    this.addOns = addOns;
    this.productsSold = productsSold;
    this.submitter = submitter;
    this.discrepancies = discrepancies;
  }

  id: string;
  created: string;
  reportDate: string;
  cash: number;
  check: number;
  deposit: number;
  totalsMatch: boolean;
  uniformDeposit: number;
  recieptsMatch: boolean;
  returnTotal: number;
  spaFinderMatch: boolean;
  compsVerified: boolean;
  luxeBuxe: number;
  tipsMember: number;
  tipsGuest: number;
  tipsEmployee: number;
  couponsVerified: boolean;
  grouponsRedeemed: boolean;
  billingVerified: boolean;
  drawCountOpen: boolean;
  drawCountMid: boolean;
  drawCountClose: boolean;
  incomingTotal: number;
  outgoingTotal: number;
  newMembers: number;
  addOns: number;
  productsSold: number;
  employeeId: string;
  submitter: Employee;
  discrepancies: DfrNote[];
}
