import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WaitList } from '../models/waitlist-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WaitListService extends BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService) {
    super(http, constants, constants.api_waitlist);
  }

  complete(itemId: string): Observable<any> {
    return this.http.delete(this.constants.api_waitlist + '/' + itemId + environment.api_code);
  }
}
