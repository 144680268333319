import { Injectable } from '@angular/core';
import { DfrService } from '../dfr.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { DfrItem } from 'src/app/models/dfr-item.model';

@Injectable({
  providedIn: 'root'
})
export class DfrListResolverService implements Resolve<DfrItem[]> {

  constructor(private svc: DfrService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DfrItem[]> | Observable<never> {

    return EMPTY;
    // return this.svc.getAddOns().pipe(take(1),
    //   mergeMap(e => {
    //     if (e) {
    //       return of(e);
    //     } else {
    //       return EMPTY;
    //     }
    //   }));
  }
}
