import { Component, OnInit, Input } from '@angular/core';
import { AddOnSale } from 'src/app/models/addon-sale.model';

@Component({
  selector: 'app-addons-list-noform',
  templateUrl: './addons-list-noform.component.html',
  styleUrls: ['./addons-list-noform.component.css']
})
export class AddonsListNoformComponent implements OnInit {

  @Input() addOnSales: AddOnSale[];
  @Input() title: string;
  @Input() loading: boolean;
  @Input() emptyList: boolean;
  @Input() loadingMessage: string;
  @Input() showRemoved: boolean;
  @Input() showVerified: boolean;
  @Input() showActions: boolean;

  constructor() { }

  ngOnInit() {
  }

  viewCheck(checkUri: string) {
    window.open(checkUri);
  }
}
