import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewMember } from 'src/app/models/new-member.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-member-list',
  templateUrl: './new-member-list.component.html',
  styleUrls: ['./new-member-list.component.css']
})
export class NewMemberListComponent implements OnInit {

  constructor(private router: Router, public auth: AuthService) { }

  @Input() members: NewMember[];
  @Input() title: string;
  @Input() loading: boolean;
  @Input() emptyList: boolean;
  @Input() loadingMsg: string;
  @Output() removeMember = new EventEmitter<NewMember>();

  selectedMember: NewMember;

  ngOnInit() {
  }

  editMember(m: NewMember) {
    this.router.navigate(['/nm/' + m.id ]);
  }

  deleteItem(m: NewMember) {
    this.removeMember.emit(m);
  }

  confirmDelete(m: NewMember) {
    this.selectedMember = m;

    // Show Modal!
  }

  closeModal() {
    this.selectedMember = null;
  }

}
