import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { Observable } from 'rxjs';
import { Period } from '../models/period.model';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PeriodService extends BaseService {

  constructor(http: HttpClient, constants: ConstantsService) {
    super(http, constants, constants.api_period);
  }

  getItem(itemId: string): Observable<Period> {
    return this.http.get<Period>(this.constants.api_period_item + '/' + itemId + environment.api_code)
    .pipe(catchError((e: any) => this.handleError(e)));
  }

  getList(type?: string): Observable<Period[]> {
    let uri = this.constants.api_period_list;

    if(type){
      uri += '/' + type;
    }

    return this.http.get<Period[]>(uri + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
  
  getCurrent(type?: string): Observable<Period> {
    let uri = this.constants.api_period_current;

    if(type){
      uri += '/' + type;
    }

    return this.http.get<Period>(uri + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  createPitchesForPeriod(periodId: string): Observable<any> {
    return this.http.get<any>(this.constants.api_period_create_pitches + '/' + periodId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
}
