import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { MessageModel } from 'src/app/models/message.model';
import { NewMember } from 'src/app/models/new-member.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { NewMembersService } from 'src/app/services/new-members.service';

@Component({
  selector: 'app-new-member-edit',
  templateUrl: './new-member-edit.component.html',
  styleUrls: ['./new-member-edit.component.css']
})
export class NewMemberEditComponent implements OnInit {

  member: NewMember;
  memberId: string;
  newMemberForm: FormGroup;
  employee: Employee;
  employeeId: string;
  isNew: boolean;
  loading: boolean;
  loadingMsg = 'Loading Member Details...';

  constructor(private router: Router, private nm: NewMembersService, public fb: FormBuilder,
    private actRoute: ActivatedRoute, private constants: ConstantsService,
    private authService: AuthService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.loading = true;

    this.createForm();
    this.employee = this.authService.getPinnedUser();

    this.actRoute.paramMap.subscribe(p => {
      this.memberId = p.get('id');

      if (this.memberId === 'new') {
        this.loading = false;
        this.isNew = true;
        this.member = new NewMember('', this.employee.id, this.employee, '', '', '', 'New Member', false, '');
        this.editMember();
      } else {
        this.loading = true;
        this.nm.get(this.memberId).subscribe(d => {
          this.isNew = false;
          this.member = d;
          this.editMember();
          this.loading = false;
        });
      }
    });
  }

  backToList() {
    this.router.navigate(['nm']);
  }

  createForm() {
    const emp = this.authService.getPinnedUser();
    this.newMemberForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      prepaid: [false],
      joinDate: ['', [Validators.required]],
    });
  }

  joinDateFilter() {
    this.member.joinDate = this.datePipe.transform(this.member.joinDate, 'yyyy-MM-dd');
  }

  editMember() {
    // If New then don't patch the prepaid value
    if (this.isNew) {
      this.newMemberForm.patchValue({
        firstName: this.member.memberFirstName,
        lastName: this.member.memberLastName,
        email: this.member.memberEmail,
        joinDate: this.member.joinDate});
    } else {
      this.joinDateFilter();
      this.newMemberForm.patchValue({
        firstName: this.member.memberFirstName,
        lastName: this.member.memberLastName,
        email: this.member.memberEmail,
        prepaid: this.member.prepaid,
        joinDate: this.member.joinDate
      });
    }
  }

  createNewMember(m: NewMember) {
    this.loading = true;
    this.loadingMsg = 'Adding Member...';

    m.employeeId = this.employee.id;

    this.nm.add(m).subscribe((e: any) => {
      this.constants.sendMessage.next(new MessageModel('Member Added!', 'alert-success'));
      this.member = e;
      this.isNew = false;
      this.loading = false;
      this.backToList();
    });
  }

  updateMember(m: NewMember) {
    this.loading = true;
    this.loadingMsg = 'Updating Member....';
    this.nm.update(m).subscribe(() => {
      this.constants.sendMessage.next(new MessageModel('Member Updated!', 'alert-success'));
      this.backToList();
    });
  }
}
