import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Guest } from '../models/guest.model';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GuestService extends BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService) {
    super(http, constants, constants.api_guests)
  }

  getByPartition(partitionId: string) {
    return this.http.get<Guest[]>(this.constants.api_guests_by_partition + '/' + partitionId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
}
