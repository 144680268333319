import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { NewMember } from 'src/app/models/new-member.model';

@Component({
  selector: 'app-new-member-form',
  templateUrl: './new-member-form.component.html',
  styleUrls: ['./new-member-form.component.css']
})
export class NewMemberFormComponent implements OnInit {

  @Input() member: NewMember;
  @Input() newMemberForm: FormGroup;
  @Input() employee: Employee;
  @Output() submitChanges = new EventEmitter<NewMember>();
  @Output() submitNewMember = new EventEmitter<NewMember>();
  @Input() isNew: boolean;

  joinMaxDate: string;

  constructor(private router: Router, private datePipe: DatePipe) { }

  ngOnInit() {
  }

  create() {
    this.member.memberFirstName = this.newMemberForm.value.firstName;
    this.member.memberLastName = this.newMemberForm.value.lastName;
    this.member.memberEmail = this.newMemberForm.value.email;
    this.member.prepaid = this.newMemberForm.value.prepaid;
    this.member.joinDate = this.newMemberForm.value.joinDate;
    this.submitNewMember.emit(this.member);
  }

  update() {
    this.member.memberFirstName = this.newMemberForm.value.firstName;
    this.member.memberLastName = this.newMemberForm.value.lastName;
    this.member.memberEmail = this.newMemberForm.value.email;
    this.member.prepaid = this.newMemberForm.value.prepaid;
    this.member.joinDate = this.newMemberForm.value.joinDate;
    this.submitChanges.emit(this.member);
  }

  goBack() {
    this.router.navigate(['/nm']);
  }
}
