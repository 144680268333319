import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConstantsService } from 'src/app/services/constants.service';
import { Guest } from 'src/app/models/guest.model';
import { AuthService } from 'src/app/services/auth.service';
import { CallLogService } from 'src/app/services/call-log.service';
import { GuestService } from 'src/app/services/guest.service';

@Component({
  selector: 'app-calls-complete-list',
  templateUrl: './calls-complete-list.component.html',
  styleUrls: ['./calls-complete-list.component.css']
})
export class CallsCompleteListComponent implements OnInit {

  @Input() tableData: any[];
  @Input() title: string;
  @Input() emptyList: boolean;
  @Input() loading: boolean;
  @Input() filter: string;
  @Output() updateItem = new EventEmitter();

  addSuccess = false;
  currentGuest: Guest;
  script: string;
  loadingMessage = 'Loading Completed Calls List...';

  constructor(private constants: ConstantsService, private auth: AuthService,
    private logService: CallLogService, private guestService: GuestService) { }

  ngOnInit() {

  }

  openAcct(guestId: string) {
    const uri = this.constants.venge_acct_path + guestId;
    window.open(uri);
  }

  closeCall() {
    this.currentGuest = null;
    this.script = null;
  }

  callGuest(guest: Guest): void {
    this.currentGuest = guest;
    console.log('Guest: ' + JSON.stringify(guest));
  }

  setStatus(item: any) {
    // Call Api and Close modal
    const emp = this.auth.getPinnedUser();
    this.logService.logCall(item, this.currentGuest, emp).subscribe();
    this.closeCall();
  }

  returnToList() {
    this.currentGuest.lastCallResult = '';
    this.guestService.update(this.currentGuest).subscribe(() => alert('Guest status was removed and is now back on the list!'));
  }
}
