import { Component, OnInit } from '@angular/core';
import { Period } from 'src/app/models/period.model';
import { ClosingNumber } from 'src/app/models/closing-number.model';
import { EmployeeService } from 'src/app/services/employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreStat } from 'src/app/models/store-stat.model';
import { StoreStatsService } from 'src/app/services/store-stats.service';
import { PeriodService } from 'src/app/services/period.service';

@Component({
  selector: 'app-mgmt-pitches',
  templateUrl: './mgmt-pitches.component.html',
  styleUrls: ['./mgmt-pitches.component.css']
})
export class MgmtPitchesComponent implements OnInit {

  period: Period;
  periods: Period[];
  closingRanks: ClosingNumber[];
  storeStats: StoreStat;
  emptyClosingList: boolean;
  showEdit = true;
  loading: boolean;
  loadingMsg = 'Loading Closing Numbers for Selected Period...';
  working: boolean;
  workingMessage = 'working on it...';
  statsAvailable: boolean;

  constructor(private employeesService: EmployeeService, private ss: StoreStatsService,
    private ps: PeriodService, private actRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.actRoute.data.subscribe((d: { period: Period, periods: Period[] }) => {
      this.period = d.period;
      this.periods = d.periods;
      this.getStoreStatsForPeriod(this.period.id);
      this.getClosingNumbersForPeriod(this.period.id);
    });
  }

  getStoreStatsForPeriod(periodId?: string) {
    this.ss.getForPeriod(periodId).subscribe(d => {
      this.storeStats = d;
      if (this.storeStats) {
        this.statsAvailable = true;
      } else {
        this.statsAvailable = false;
      }
    });
  }

  getClosingNumbersForPeriod(periodId?: string) {
    this.loading = true;
    this.emptyClosingList = true;
    this.employeesService.getClosingNumbers(periodId).subscribe(data => {
      this.closingRanks = data;

      if (data && data.length > 0) {
        this.emptyClosingList = false;
      } else {
        this.emptyClosingList = true;
      }
      this.loading = false;
    });
  }

  changePeriod(p: string) {
    this.ps.getItem(p).subscribe(d => {
      this.period = d;
      this.getStoreStatsForPeriod(p);
      this.getClosingNumbersForPeriod(p);
    });
  }

  editStoreStats() {
    this.router.navigate(['/mgmt/stats/' + this.period.id]);
  }

  addMissingEmployees() {
    this.ps.createPitchesForPeriod(this.period.id).subscribe(d => {
      this.getStoreStatsForPeriod(this.period.id);
      this.getClosingNumbersForPeriod(this.period.id);
    });
  }
}
