import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DfrItem } from 'src/app/models/dfr-item.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dfr-edit',
  templateUrl: './dfr-edit.component.html',
  styleUrls: ['./dfr-edit.component.css']
})
export class DfrEditComponent implements OnInit {

  dfrForm: FormGroup;
  item: DfrItem;

  constructor(private fb: FormBuilder, private actRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.dfrForm = this.fb.group({
      reportDate: ['', [Validators.required]],
      cash: ['', [Validators.required]],
      check: ['', [Validators.required]],
      deposit: ['', [Validators.required]],
      uniformDeposit: ['', [Validators.required]],
      recieptsMatch: ['', [Validators.required]],
      returnTotal: ['', [Validators.required]],
      spaFinderMatch: ['', [Validators.required]]
    });
  }

  editEmployee() {
    // this.dfrForm.patchValue({
    //   firstName: this.item.,
    //   lastName: this.employee.lastName,
    //   isActive: this.employee.isActive,
    //   isManager: this.employee.isManager
    // });
  }

  backToList() {
    this.router.navigate(['/mgmt/dfrs']);
  }
}
