import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(public http: HttpClient, public constants: ConstantsService, public api: string = environment.api_host) { }

  changes: BehaviorSubject<any> = new BehaviorSubject(false);

  handleError(error: any) {
    console.log('Error received: ' + JSON.stringify(error));
    throw (error);
    return EMPTY;
  }

  get(itemId: string): Observable<any> {
    return this.http.get(this.api + '/' + itemId + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  getAll(): Observable<any[]> {
    return this.http.get<any[]>(this.api + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  add(item: any): Observable<any> {
    return this.http.post(this.api + environment.api_code, item)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  update(item: any) {
    return this.http.put(this.api + '/' + item.id + environment.api_code, item)
      .pipe(catchError((e: any) => this.handleError(e)));
  }

  remove(item: any): Observable<any> {
    return this.http.delete(this.api + '/' + item.id + environment.api_code)
      .pipe(catchError((e: any) => this.handleError(e)));
  }
}
