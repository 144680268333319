import { Injectable } from '@angular/core';
import { EmployeeService } from '../employee.service';
import { Employee } from 'src/app/models/employee.model';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, EMPTY, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployeeResolverService implements Resolve<Employee> {

  constructor(private svc: EmployeeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Employee> | Observable<never> {
    const id = route.paramMap.get('id');

    return this.svc.get(id).pipe(take(1),
      mergeMap(e => {
        if (e) {
          return of(e);
        } else {
          return EMPTY;
        }
      }));
  }
}
