import { Component, OnInit, Input } from '@angular/core';
import { Rank } from 'src/app/models/rank.model';

@Component({
  selector: 'app-monthly-contest-view',
  templateUrl: './monthly-contest-view.component.html',
  styleUrls: ['./monthly-contest-view.component.css']
})
export class MonthlyContestViewComponent implements OnInit {

  @Input() employeesRanked: Rank[];
  @Input() contestName: string;

  constructor() { }

  ngOnInit() { }

}
