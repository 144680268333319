import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Employee } from 'src/app/models/employee.model';
import { PinQuery } from 'src/app/models/pin-query.model';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-employee-pin-form',
  templateUrl: './employee-pin-form.component.html',
  styleUrls: ['./employee-pin-form.component.css']
})
export class EmployeePinFormComponent implements OnInit {

  @Input() employee: Employee;
  @Input() employeePinForm: FormGroup;
  @Output() submitChanges = new EventEmitter<PinQuery>();

  pinQuery: PinQuery;

  constructor(private router: Router, private es: EmployeeService) { }

  ngOnInit() {
    this.pinQuery = new PinQuery();
  }

  submit() {
    this.pinQuery.id = this.employee.id;
    // Grab the current user
    this.pinQuery.pin = this.employeePinForm.value.pin;
    this.submitChanges.emit(this.pinQuery);
  }

  updatePin() {
    this.es.pinUpsert(this.employee.id, this.pinQuery);
  }

  goBack() {
    this.router.navigate(['/mgmt/employees/' + this.employee.id ]);
  }
}
