import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/services/employee.service';
import { ConstantsService } from 'src/app/services/constants.service';
import { Employee } from 'src/app/models/employee.model';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mgmt-employees',
  templateUrl: './mgmt-employees.component.html',
  styleUrls: ['./mgmt-employees.component.css']
})
export class MgmtEmployeesComponent implements OnInit {

  employees: Employee[];
  filterActive = false;
  constructor(private employeeService: EmployeeService, private router: Router) { }

  ngOnInit() {
    this.filterActive = false;
    this.getEmployees();
  }

  getEmployees() {
    if (this.filterActive) {
      this.employeeService.getAllRecords().subscribe(d => {
        this.employees = d;
      });
    } else {
      this.employeeService.getAll().subscribe(d => {
        this.employees = d;
      });
    }
  }

  filterList(flag: any) {
    this.filterActive = !this.filterActive;
    this.getEmployees();
  }

  addEmployee() {
    this.router.navigate(['/mgmt/employees/new']);
  }
}
